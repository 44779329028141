/*
 * action types
 */

import GET_ALL_EARNINGS from "src/graphql/queries/getAllEarnings.gql";
import UPDATE_EARNING_MUTATION from "src/graphql/mutations/updateEarning.gql";

export async function getAllEarnings(client) {
  const data = await client.query({
    query: GET_ALL_EARNINGS,
    fetchPolicy: "network-only",
  });

  return data.data.Gains.list;
}

export async function updateEarning(input, client) {
  console.log("in action", input);
  const data = await client.mutate({
    mutation: UPDATE_EARNING_MUTATION,
    variables: {input},
  });

  return data.data.Gains.update;
}
