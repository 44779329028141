import React from "react";

import Logo from "src/assets/img/logo.png";

const LoadingHome = () => (
  <div className="center-screen">
    <img alt="Chargement..." src={Logo} className="pulse" />
  </div>
);

export default LoadingHome;
