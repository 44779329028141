import React, {useState, useEffect} from "react";
import {DualAxes} from "@ant-design/charts";

const DualLines = props => {
  const {data} = props;
  const MONTHS = [
    "",
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  console.log(data);
  var config = {
    data: [data, data],
    xField: "month",
    yField: ["value", "count"],
    forceFit: true,
    meta: {
      value: {
        alias: "Bénéfices",
        formatter: v => `${parseFloat(v).toFixed(2)} €`,
      },
      count: {
        alias: "Transactions",
      },
      month: {
        formatter: v => MONTHS[parseInt(v)],
      },
    },
    geometryOptions: [
      {
        geometry: "column",
        isGroup: false,
      },
      {
        geometry: "line",
        lineStyle: {
          lineWidth: 2,
        },
      },
    ],
  };
  // {
  //   data: [data, data],
  //   title: props.title,
  //   xField: "month",
  //   yField: ["count", "value"],
  //   meta: {
  //     value: {
  //       alias: "Bénéfices",
  //       formatter: v => `${v} €`,
  //     },
  //     count: {
  //       alias: "Transactions",
  //     },
  //   },
  //   geometryOptions: [
  //     {
  //       geometry: "column",
  //       isGroup: true,
  //       seriesField: "type",
  //       columnWidthRatio: 0.4,
  //     },
  //   ],
  // };
  return <DualAxes {...config} />;
};

export default DualLines;
