import React from "react";
import {Steps} from "antd";

const {Step} = Steps;

function Timeline(props) {
  const {affaires} = props;

  return (
    <Steps current={7} status="info">
      <Step title="Offre validée" description="02/06/2016" />
      <Step title="Commande reçue" description="02/06/2016" />
      <Step title="DT-DICT" description="Intervention possible au 12/06/2016" />
      <Step
        title="Intervention"
        description="Programmée le 12/06/2016 Réalisée le 12/06/2016"
      />
      <Step title="laboratoire" description="Envoi 12/06/2016 Retour 15/12/2016" />
      <Step title="Rapport" description="Etabli le 15/06/2016" />
      <Step title="Facture" description="15/06/2016" />
    </Steps>
  );
}

export default Timeline;
