import React from "react";

// GraphQL
import {Link} from "react-router-dom";
import {Query} from "react-apollo";
import moment from "moment";
import {Badge, Card, Row, Col, Divider} from "antd";
import {orderBy} from "lodash";
import FontAwesome from "react-fontawesome";
import DualLineAxis from "components/stateless/dualLinesChart";
import {textFilter} from "react-bootstrap-table2-filter";
import Table from "components/stateless/antdTable";
import {Sorter} from "../../helpers/sorter";

/* App */
import {Loading, ScrollTable, showNotification} from "src/components/stateless"; // custom components
import {defaultSort} from "src/helpers";

import GET_EARNINGS_LIST from "src/graphql/queries/getAllEarnings.gql";

class TransactionsList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      LinetoExpand: null,
    };
  }

  createStats = transactions => {
    console.log(transactions.list);
    const {user} = this.props;
    const tva = user.needTva;
    const stats = transactions.reduce(
      (c, n) => {
        const month = moment(n.createdAt).month() + 1;
        if (!c.general[month].list.find(t => t.transaction.id === n.transaction.id)) {
          c.general[month].count += 1;
        }
        c.general[month].list.push(n);
        c.general[month].value += n.amount - n.vatAmount;
        if (n.from.id === this.props.user.id && n.user.id === user.id) {
          c.personal[month].list.push(n);
          c.personal[month].count += 1;
          c.personal[month].value += tva ? n.amount - n.vatAmount : n.amount;
          c.personal[month].globalCount += 1;
          c.personal[month].globalValue += tva ? n.amount - n.vatAmount : n.amount;
        } else if (n.user.id === user.id) {
          c.fromMyChilds[month].list.push(n);
          c.fromMyChilds[month].count += 1;
          c.fromMyChilds[month].value += tva ? n.amount - n.vatAmount : n.amount;
          c.personal[month].globalCount += 1;
          c.personal[month].globalValue += tva ? n.amount - n.vatAmount : n.amount;
        } else if (n.user.firstname === "A2V") {
          c.a2v[month].list.push(n);
          c.a2v[month].count += 1;
          c.a2v[month].value += n.amount - n.vatAmount;
        }
        return c;
      },
      {
        personal: {
          1: {list: [], value: 0, count: 0, globalCount: 0, globalValue: 0},
          2: {list: [], value: 0, count: 0, globalCount: 0, globalValue: 0},
          3: {list: [], value: 0, count: 0, globalCount: 0, globalValue: 0},
          4: {list: [], value: 0, count: 0, globalCount: 0, globalValue: 0},
          5: {list: [], value: 0, count: 0, globalCount: 0, globalValue: 0},
          6: {list: [], value: 0, count: 0, globalCount: 0, globalValue: 0},
          7: {list: [], value: 0, count: 0, globalCount: 0, globalValue: 0},
          8: {list: [], value: 0, count: 0, globalCount: 0, globalValue: 0},
          9: {list: [], value: 0, count: 0, globalCount: 0, globalValue: 0},
          10: {list: [], value: 0, count: 0, globalCount: 0, globalValue: 0},
          11: {list: [], value: 0, count: 0, globalCount: 0, globalValue: 0},
          12: {list: [], value: 0, count: 0, globalCount: 0, globalValue: 0},
        },
        fromMyChilds: {
          1: {list: [], value: 0, count: 0},
          2: {list: [], value: 0, count: 0},
          3: {list: [], value: 0, count: 0},
          4: {list: [], value: 0, count: 0},
          5: {list: [], value: 0, count: 0},
          6: {list: [], value: 0, count: 0},
          7: {list: [], value: 0, count: 0},
          8: {list: [], value: 0, count: 0},
          9: {list: [], value: 0, count: 0},
          10: {list: [], value: 0, count: 0},
          11: {list: [], value: 0, count: 0},
          12: {list: [], value: 0, count: 0},
        },
        general: {
          1: {list: [], value: 0, count: 0},
          2: {list: [], value: 0, count: 0},
          3: {list: [], value: 0, count: 0},
          4: {list: [], value: 0, count: 0},
          5: {list: [], value: 0, count: 0},
          6: {list: [], value: 0, count: 0},
          7: {list: [], value: 0, count: 0},
          8: {list: [], value: 0, count: 0},
          9: {list: [], value: 0, count: 0},
          10: {list: [], value: 0, count: 0},
          11: {list: [], value: 0, count: 0},
          12: {list: [], value: 0, count: 0},
        },
        a2v: {
          1: {list: [], value: 0, count: 0},
          2: {list: [], value: 0, count: 0},
          3: {list: [], value: 0, count: 0},
          4: {list: [], value: 0, count: 0},
          5: {list: [], value: 0, count: 0},
          6: {list: [], value: 0, count: 0},
          7: {list: [], value: 0, count: 0},
          8: {list: [], value: 0, count: 0},
          9: {list: [], value: 0, count: 0},
          10: {list: [], value: 0, count: 0},
          11: {list: [], value: 0, count: 0},
          12: {list: [], value: 0, count: 0},
        },
      }
    );
    return stats;
  };

  renderRow = curr => {
    return {
      ...curr,
      key: curr.id,
      tva: curr.user.needTva ? curr.vatAmount + " €" : 0,
      nettyId: curr.transaction.nettyId,
      user: `${curr.from.firstname} ${curr.from.lastname}`,
      userId: curr.user.id,
      from: `${curr.from.firstname} ${curr.from.lastname}`,
      to: `${curr.user.firstname} ${curr.user.lastname}`,
      date: moment(curr.createdAt).format("DD/MM/YYYY HH:mm:ss"),
      // view: (
      //   <Link
      //     className="btn btn-primary"
      //     onClick={e => this.props.onEditClicked(curr.id)}
      //   >
      //     <i className="fa fa-eye" />
      //   </Link>
      // ),
      amount: curr.amount + " €",
      profit: curr.user.needTva
        ? `${parseFloat(curr.amount - curr.vatAmount).toFixed(2)} €`
        : `${curr.amount} €`,
      commissionAmount: parseFloat(curr.transaction.commissionAmount).toFixed(2) + " €",
      mandat: (
        <div>
          <span>
            <FontAwesome name="sign-in-alt" style={{color: "#1890ff"}} />
            Entrant:
            {curr.representativeInput
              ? ` ${curr.representativeInput.firstname} ${curr.representativeInput.lastname}`
              : " Aucun"}
          </span>
          <br />
          <span>
            <FontAwesome name="sign-out-alt" style={{color: "rgb(88, 196, 26)"}} />
            Sortant:
            {curr.user ? ` ${curr.user.firstname} ${curr.user.lastname}` : " Aucun"}
          </span>
        </div>
      ),
    };
  };

  render() {
    return (
      <Query query={GET_EARNINGS_LIST} fetchPolicy="network-only">
        {({loading, error, data}) => {
          if (loading) return <Loading />;
          if (error) return showNotification("error", "", error.message);

          const {Gains} = data;
          const {user} = this.props;

          const listByYear = Gains.list.filter(e => {
            console.log(moment(e.createdAt).year(), moment().year());
            if (moment(e.createdAt).year() === moment().year()) {
              return e;
            }
          });

          const statsHistory = this.createStats(listByYear);

          console.log(statsHistory);

          const personalStatsByMonth = [];
          const fromMyChildsStats = [];
          const myGlobalStats = [];
          const globalStats = [];
          const globalA2vStats = [];

          Object.keys(statsHistory.personal).map(key => {
            personalStatsByMonth.push({
              month: key,
              value: statsHistory.personal[key].value,
              count: statsHistory.personal[key].count,
            });
          });

          Object.keys(statsHistory.fromMyChilds).map(key => {
            fromMyChildsStats.push({
              month: key,
              value: statsHistory.fromMyChilds[key].value,
              count: statsHistory.fromMyChilds[key].count,
            });
          });

          Object.keys(statsHistory.personal).map(key => {
            myGlobalStats.push({
              month: key,
              value: statsHistory.personal[key].globalValue,
              count: statsHistory.personal[key].globalCount,
            });
          });

          Object.keys(statsHistory.general).map(key => {
            globalStats.push({
              month: key,
              value: statsHistory.general[key].value,
              count: statsHistory.general[key].count,
            });
          });

          Object.keys(statsHistory.a2v).map(key => {
            globalA2vStats.push({
              month: key,
              value: statsHistory.a2v[key].value,
              count: statsHistory.a2v[key].count,
            });
          });

          const defaultSorted = [
            {
              dataField: "date", // if dataField is not match to any column you defined, it will be ignored.
              order: "asc", // desc or asc
            },
          ];

          const columns = [
            {
              dataField: "nettyId",
              text: "Id du bien netty",
              ...defaultSort,
            },
            {
              dataField: "date",
              text: "Date",
              ...defaultSort,
            },
            {
              dataField: "user",
              text: "Source",
              ...defaultSort,
              formatter: (cell, row) => cell,
              filterValue: (cell, row) => cell,
            },
            {
              dataField: "to",
              text: "Bénéficiaire",
              ...defaultSort,
              formatter: (cell, row) => cell,
              filterValue: (cell, row) => cell,
            },
            {
              dataField: "type",
              text: "Type",
              ...defaultSort,
            },
            {
              dataField: "amount",
              text: "Base",
              ...defaultSort,
            },
            {
              dataField: "profit",
              text: "Bénéfice",
              ...defaultSort,
            },
            {
              dataField: "tva",
              text: "TVA",
              ...defaultSort,
            },
          ];

          const subColumns = [
            {
              dataIndex: "nettyId",
              title: "Id du bien netty",
            },
            {
              dataIndex: "date",
              title: "Date",
              sorter: (a, b) =>
                moment(a.date, "DD/MM/YYYY HH:mm:ss").unix() -
                moment(b.date, "DD/MM/YYYY HH:mm:ss").unix(),
              defaultSortOrder: "descend",
            },
            {
              dataIndex: "from",
              title: "De",
            },
            {
              dataIndex: "to",
              title: "Bénéficiaire",
            },
            {
              dataIndex: "type",
              title: "Type",
            },
            // {
            //   dataIndex: "amount",
            //   title: "Base",
            // },
            {
              dataIndex: "profit",
              title: "Honoraires HT",
            },
          ];

          let gainsList = Gains.list;

          if (user.role.name !== "Admin") {
            // if not admin filter user own transactions or from his childrens
            gainsList = Gains.list.filter(t => {
              if (
                t.user.id === user.id ||
                (user.childrens.length && user.childrens.find(u => u.id === t.user.id))
              ) {
                return t;
              }
            });
          }

          const list = gainsList.map(t => this.renderRow(t));

          return (
            <div>
              <Row>
                <Col span={24}>
                  {user.role.name === "Admin" && (
                    <div>
                      <h5
                        style={{
                          background: "black",
                          color: "white",
                          padding: 10,
                          borderRadius: 4,
                        }}
                      >
                        Statistiques globales {moment().format("YYYY")}
                      </h5>
                      <Row gutter={16} style={{marginTop: 10}}>
                        <Col span={12}>
                          <Card
                            title={
                              <h5>
                                <FontAwesome
                                  name="chart-line"
                                  style={{color: "#1890ff"}}
                                />
                                CA global Société (HT)
                              </h5>
                            }
                          >
                            <DualLineAxis data={globalStats} />
                          </Card>
                        </Col>
                        <Col span={12}>
                          <Card
                            title={
                              <h5>
                                <FontAwesome
                                  name="chart-line"
                                  style={{color: "#1890ff"}}
                                />
                                CA mensuel A2V (HT)
                              </h5>
                            }
                          >
                            <DualLineAxis data={globalA2vStats} />
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <h5
                    style={{
                      background: "black",
                      color: "white",
                      padding: 10,
                      marginTop: user.role.name === "Admin" ? 20 : 0,
                      borderRadius: 4,
                    }}
                  >
                    Statistiques Personnelles
                  </h5>
                  <Row gutter={16} style={{marginTop: 20}}>
                    <Col span={8}>
                      <Card
                        title={
                          <h5>
                            <FontAwesome name="chart-line" style={{color: "#1890ff"}} />
                            CA Production personnelle (HT)
                          </h5>
                        }
                      >
                        <DualLineAxis data={personalStatsByMonth} />
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card
                        title={
                          <h5>
                            <FontAwesome name="chart-pie" style={{color: "#1890ff"}} />
                            CA rémunération équipe (HT)
                          </h5>
                        }
                      >
                        <DualLineAxis data={fromMyChildsStats} />
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card
                        title={
                          <h5>
                            <FontAwesome name="euro-sign" style={{color: "#1890ff"}} />
                            Total Chiffre d'affaire personnel (HT)
                          </h5>
                        }
                      >
                        <DualLineAxis data={myGlobalStats} />
                      </Card>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={16} style={{marginTop: 20}}>
                    <Col span={24}>
                      <Card
                        title={
                          <h5>
                            <FontAwesome name="chart-line" style={{color: "#1890ff"}} />
                            Table des répartitions
                          </h5>
                        }
                      >
                        <Table
                          search
                          data={list}
                          columns={subColumns}
                          {...this.props}
                          //defaultSorted={defaultSorted}
                          //sizePerPage={25}
                          //expandRow={expandRow}
                        />
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default TransactionsList;
