const config = {
  hasMasternaut: false,
  fileTypes: [
    {
      value: "plan",
      label: "Plan",
    },
    {
      value: "offer",
      label: "Offre",
    },
    {
      value: "commande",
      label: "Commande",
    },
    {
      value: "bpa",
      label: "Bon pour accord",
    },
    {
      value: "arrete",
      label: "Arrêté",
    },
    {
      value: "dict",
      label: "DT-DICT",
    },
    {
      value: "rapportAnalyse",
      label: "Rapport d'analyse laboratoire",
    },
    {
      value: "rapportDefinitif",
      label: "Rapport définitif",
    },
    {
      value: "pvReception",
      label: "PV de réception",
    },
    {
      value: "releveContradictoire",
      label: "Relevé contradictoire",
    },
    {
      value: "feuilleSaisie",
      label: "Feuille de saisie",
    },
    {
      value: "template",
      label: "Modèle de document",
    },
    {
      value: "divers",
      label: "Divers",
    },
    {
      value: "kml",
      label: "Fichiers KML",
    },
  ],
};

export default config;
