/*
 * action types
 */

import DELETE_FILE_MUTATION from "src/graphql/mutations/deleteFile.gql";
import ADD_FILE_MUTATION from "src/graphql/mutations/addFile.gql";

export const ADD_FILE = "ADD_FILE";
export const DELETE_FILE = "DELETE_FILE";

/*
 * other constants
 */

export const VisibilityFilters = {
  ADD_FILE: "ADD_FILE",
  DELETE_FILE: "DELETE_FILE",
};

/*
 * action creators
 */

export async function addFile(input, client) {
  const data = await client.mutate({
    mutation: ADD_FILE_MUTATION,
    variables: {input},
  });

  return data.data.File.add;
}

export async function deleteFile(list, client) {
  const data = await client.mutate({
    mutation: DELETE_FILE_MUTATION,
    variables: {list},
  });

  return data.data.File.delete;
}
