import React, {Component} from "react";
import {Menu, Icon, Layout, Tabs, Row, Col, Card} from "antd";
import {Link} from "react-router-dom";
import FontAwesome from "react-fontawesome";
import gql from "graphql-tag";

import TransactionsStatsList from "src/components/TransactionsStats/list";
import EditTransactionsStats from "src/components/TransactionsStats/edit";
import {getGeneralStats} from "src/actions/stats";
const {TabPane} = Tabs;

class TransactionsStats extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      current: "list",
      mode: "list",
      renderChild: true,
    };
  }

  componentDidMount = async () => {
    this.props.client
      .subscribe({
        query: gql`
          subscription {
            statsUpdated {
              transactions
              userGains
              a2vGainsCount
              a2vGainsTransactionsCount
            }
          }
        `,
        variables: {},
      })
      .subscribe(async (data, client) => {
        try {
          this.setState({loading: true, renderChild: false});
          this.setState({
            current: "list",
            mode: "list",
            loading: false,
          });
          this.setState({
            renderChild: true,
          });
        } catch (err) {
          console.log(err);
        }
      });
  };

  componentWillReceiveProps = async nextProps => {
    if (!nextProps) {
      return;
    }
    const currentMode = this.state.mode;
    const {mode} = nextProps;

    if (currentMode !== mode) {
      this.setState({loading: true});
      this.setState({loading: false, mode});
    }
  };

  handleClick = e => {
    this.setState({loading: true});
    this.setState({
      current: e,
      mode: e,
      currentId: null,
      loading: false,
    });
  };

  onEditClicked = id => {
    this.setState({loading: true});
    this.setState({
      current: "add",
      mode: "edit",
      currentId: id,
      loading: false,
    });
  };

  render() {
    const {current, currentId, mode} = this.state;

    return (
      <div>
        <Row>
          <Col span={24}>
            {this.state.renderChild ? (
              <TransactionsStatsList
                {...this.props}
                onEditClicked={e => this.onEditClicked(e)}
              />
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}

export default TransactionsStats;
