/* eslint-disable no-underscore-dangle */
// IMPORTS

/* NPM */

// Apollo client library
import {ApolloClient} from "apollo-client";
import {WebSocketLink} from "apollo-link-ws";
import {split, ApolloLink} from "apollo-link";
import {HttpLink} from "apollo-link-http";
import {SubscriptionClient} from "subscriptions-transport-ws";
import {InMemoryCache} from "apollo-cache-inmemory";
import {getMainDefinition} from "apollo-utilities";
import {withClientState} from "apollo-link-state";
import {setContext} from "apollo-link-context";
import ws from "ws";

/* ReactQL */

const uri = "https://api.lart2vendreimmobilier.com/graphql";

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const httpLink = new HttpLink({uri});

const wsClient = process.browser
  ? new SubscriptionClient("wss://api.lart2vendreimmobilier.com/subscriptions", {
      options: {
        reconnect: true,
        lazy: true,
      },
      ws,
    })
  : null;

const wsLink = new WebSocketLink(wsClient);

wsLink.subscriptionClient.on("connecting", () => {
  console.log("connecting");
});

wsLink.subscriptionClient.on("connected", () => {
  console.log("connected");
});

wsLink.subscriptionClient.on("reconnecting", () => {
  console.log("reconnecting");
});

wsLink.subscriptionClient.on("reconnected", () => {
  console.log("reconnected");
});

wsLink.subscriptionClient.on("disconnected", () => {
  console.log("disconnected");
});

wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
  wsLink.subscriptionClient.maxConnectTimeGenerator.max;

const link = process.browser
  ? split(
      ({query}) => {
        const {kind, operation} = getMainDefinition(query);
        return kind === "OperationDefinition" && operation === "subscription";
      },
      wsLink,
      httpLink
    )
  : httpLink;

const authLink = setContext((_, {headers}) => {
  const token = process.browser ? localStorage.getItem("adv_stats") : "";
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const cache = new InMemoryCache();

const stateLink = withClientState({
  cache,
  resolvers: {},
});

export function createClient() {
  const client = new ApolloClient({
    link: ApolloLink.from([stateLink, authLink.concat(link)]),
    cache,
    defaultOptions,
  });
  return client;
}

// Creates a new browser client
export function browserClient() {
  // If we have an internal GraphQL server, we need to append it with a
  // call to `getServerURL()` to add the correct host (in dev + production)

  return createClient();
}
