// React
import React from "react";

// Routing via React Router
import {Link, Route, Switch, withRouter} from "react-router-dom";

// <Helmet> component for setting the page title/meta tags
import Helmet from "react-helmet";
import FontAwesome from "react-fontawesome";
import {Layout, Menu, Row, Col, ConfigProvider} from "antd";
import {history} from "src/router/history";
import {withApollo} from "react-apollo";
import {flowRight as compose} from "lodash";
import ContainerDimensions from "react-container-dimensions";
import {Widget, addResponseMessage} from "react-chat-widget";

import fr_FR from "antd/lib/locale-provider/fr_FR";
import moment from "moment";
import "moment/locale/fr";

// components for main routing
import {Login, Logout} from "components/Auth";
import Home from "components/Home";
import Invoices from "components/Invoices";
import {UserPopOver, LoadingHome} from "components/stateless";
import {getUserInfo, login, logout} from "src/actions/auth";
import {ListUsers, EditUsers} from "components/Users";
import TransactionsStats from "components/TransactionsStats";

//import ProspectOffer from "components/Prospects/offer";
// import EditEstimation from 'components/Estimations/edit';
import logo from "./logo.png";
import avatar from "../../assets/img/logo_round.png";

import css from "../styles/styles.css";

/* App */
const {SubMenu} = Menu;
const {Header, Content, Sider} = Layout;
moment.locale("fr");

class Main extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isReady: false,
      collapsed: false,
      user: null,
      isLoggedIn: false,
      token: null,
    };
  }

  componentDidMount() {
    window.addEventListener("load", this.handleLoad);
    this.checkToken();
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.handleLoad);
  }

  onCollapse = collapsed => {
    this.setState({
      collapsed,
    });
  };

  handleLoad = () => {
    this.setState({
      isReady: true,
    });
  };

  handleNewUserMessage = newMessage => {
    console.log(`New message incoming! ${newMessage}`);
    // Now send the message throught the backend API
  };

  checkToken = async () => {
    try {
      const token = await localStorage.getItem("adv_stats");
      if (token) {
        this.setState({
          loading: true,
        });
        //await this.props.login();
        const user = await getUserInfo(this.props.client);
        if (!user) {
          this.setState({
            loading: false,
            isLoggedIn: false,
            token,
          });
          await localStorage.removeItem("adv_stats");
          //await logout();
          return;
        }
        this.setState({
          loading: false,
          user,
          isLoggedIn: true,
          token,
        });
      }
    } catch (error) {
      this.setState({
        user: null,
        loading: false,
      });
    }
  };

  render() {
    const {location} = this.props;
    const {loading, isReady, collapsed, user, isLoggedIn} = this.state;

    const selected = location.pathname === "/" ? "home" : location.pathname;
    const openedKeys = ["transactions"].map(p => (selected.match(p) ? p : null));

    if (loading) {
      return <LoadingHome />;
    }

    if (!isLoggedIn) {
      //this.setState({loading: false});
      return <Login logUser={this.checkToken} />;
    }

    if ((!isReady && window.location.hostname !== "localhost") || !user) {
      return <LoadingHome />;
    }

    const AdvMenu = props => (
      <React.Fragment>
        <Menu
          inlineIndent={10}
          mode="inline"
          theme="dark"
          defaultSelectedKeys={[...selected]}
          defaultOpenKeys={[...openedKeys]}
          selectedKeys={selected || "home"}
          // style={{ borderRight: 0 }}
          onClick={props.onClick}
        >
          <Menu.Item key="home">
            <Link to="/">
              <FontAwesome name="home" />
              <span className="nav-text">Accueil</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/earnings">
            <Link to="/earnings">
              <FontAwesome name="euro-sign" />
              <span className="nav-text">Honoraires</span>
            </Link>
          </Menu.Item>
          {user.role.name === "Admin" || (user.childrens && user.childrens.length) ? (
            <Menu.Item key="/billing">
              <Link to="/billing">
                <FontAwesome name="file-invoice" />
                <span className="nav-text">Facturation</span>
              </Link>
            </Menu.Item>
          ) : (
            ""
          )}
        </Menu>
      </React.Fragment>
    );

    return (
      <div>
        <Helmet
          title="Art2vendreimmobiler Stats - accueil"
          meta={[
            {
              name: "Accueil stats Art2vendreimmobiler",
            },
          ]}
        />
        <ConfigProvider locale={fr_FR}>
          <Layout
            style={{
              height: "100vh",
            }}
          >
            <Header className="header">
              <Row gutter={8}>
                <Col span={4}>
                  <img src={logo} className="logo" alt="logo Art2vendreimmobiler" />
                </Col>
                <Col span={20}>
                  <Row gutter={8} type="flex" justify="end">
                    <UserPopOver user={user} />
                    {/* <Search
                      history={history}
                      style={{
                        position: "relative",
                        right: -38,
                      }}
                    /> */}
                  </Row>
                </Col>
              </Row>
            </Header>
            <Layout>
              <Sider
                style={{
                  top: -4,
                }}
                collapsible
                collapsed={collapsed}
                onCollapse={this.onCollapse}
                breakpoint="lg"
                collapsedWidth="0"
              >
                <AdvMenu />
              </Sider>
              <ContainerDimensions>
                {({width, height}) => (
                  <Layout
                    style={{
                      height,
                    }}
                  >
                    <Content
                      style={{
                        background: "#fff",
                        padding: 10,
                        margin: 16,
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      <Switch>
                        <Route
                          path="/"
                          render={props => (
                            <Home {...props} client={this.props.client} user={user} />
                          )}
                          exact
                        />
                        <Route
                          path="/earnings"
                          render={props => (
                            <TransactionsStats
                              {...props}
                              client={this.props.client}
                              user={user}
                            />
                          )}
                          //exact
                        />
                        <Route
                          path="/billing"
                          render={props => (
                            <Invoices {...props} client={this.props.client} user={user} />
                          )}
                          //exact
                        />
                        <Route
                          path="/logout"
                          render={props => (
                            <Logout {...props} client={this.props.client} user={user} />
                          )}
                        />
                        <React.Fragment>
                          <Route
                            path="/users"
                            render={props => (
                              <ListUsers
                                {...props}
                                client={this.props.client}
                                user={user}
                              />
                            )}
                            exact
                          />
                          <Route
                            path="/users/show/add"
                            render={props => (
                              <EditUsers
                                {...props}
                                client={this.props.client}
                                user={user}
                              />
                            )}
                            exact
                          />
                          <Route
                            path="/users/:id"
                            render={props => (
                              <EditUsers
                                {...props}
                                client={this.props.client}
                                user={user}
                              />
                            )}
                            exact
                          />
                        </React.Fragment>
                      </Switch>
                    </Content>
                  </Layout>
                )}
              </ContainerDimensions>
            </Layout>
          </Layout>
        </ConfigProvider>
      </div>
    );
  }
}

export default withApollo(withRouter(Main));
