import React, {useEffect, useState} from "react";
import {Drawer, Form, message, Button, Col, Row, Input, DatePicker, Space} from "antd";
import moment from "moment";

const InvoiceForm = props => {
  const [form] = Form.useForm();
  const [date, setDate] = useState(null);

  const onDateChange = (date, dateString) => {
    setDate(moment(date).toISOString());
  };

  const onClose = () => {
    form.resetFields();
    props.onClose();
  };

  const onFinish = values => {
    console.log("in onfinish", values);
    props.onSubmitPayment({
      id: props.invoice.id,
      payedAmount: parseFloat(values.paymentAmount),
      paymentDate: date,
    });
  };

  const onFinishFailed = () => {
    message.error("Erreur d'enregistrement!");
  };

  return (
    <>
      <Drawer
        title={`Paiement de la facture ${props.invoice && props.invoice.ref}`}
        width={600}
        onClose={onClose}
        visible={props.visible}
        bodyStyle={{paddingBottom: 80}}
      >
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{paymentAmount: props.invoice.amount, date: null}}
          hideRequiredMark
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="paymentAmount"
                label="Montant payé:"
                rules={[
                  {
                    required: true,
                    message: "Merci d'entrer un montant",
                  },
                ]}
              >
                <Input type="number" placeholder="" style={{width: 300}} />
              </Form.Item>
              <Form.Item
                name="date"
                label="Date de paiement:"
                rules={[{required: true, message: "Merci de sélectionner une date"}]}
              >
                <DatePicker onChange={onDateChange} style={{width: 300}} />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button type="primary" htmlType="submit">
                    Envoyer
                  </Button>
                  <Button onClick={onClose}>Annuler</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default InvoiceForm;
