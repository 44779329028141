import React, {Component} from "react";
import {Modal, Button, Space} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {SketchPicker} from "react-color";

class colorPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      color: props.color || "purple",
    };
  }

  componentWillReceiveProps = nextProps => {
    if (!nextProps) {
      return;
    }
    if (this.state.color !== nextProps.color) {
      this.setState({
        color: nextProps.color,
      });
    }
  };

  showModal = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  handleChangeComplete = color => {
    console.log(color);
    this.props.onChange(color.hex);
  };

  render() {
    const {visible, color} = this.state;
    console.log(visible);
    return (
      <>
        <div
          onClick={this.showModal}
          style={{
            width: 120,
            height: 24,
            borderRadius: 2,
            cursor: "pointer",
            border: "1px solid black",
            background: color || "purple",
          }}
        ></div>

        <div
          style={{
            display: visible ? "block" : "none",
            zIndex: 10,
            height: 400,
            marginTop: 10,
            width: 200,
          }}
        >
          <SketchPicker color={color} onChangeComplete={this.handleChangeComplete} />
        </div>
      </>
    );
  }
}

export default colorPicker;
