import moment from "moment";

const defaultSort = (a, b) => {
  const diff = a - b;
  return isNaN(diff) ? a.localeCompare(b) : diff ? diff / Math.abs(diff) : 0;
};

const dateSort = (dateA, dateB) =>
  defaultSort(new Date(dateA).getTime(), new Date(dateB).getTime());

export const Sorter = {
  DEFAULT: defaultSort,
  DATE: dateSort,
};
