import React from "react";
import Stats from "src/components/Stats";
import {Diagram} from "src/components/Diagram";
import Transactions from "components/Transactions";

function Home(props) {
  return !props.user.disabled ? (
    <div>
      <Stats {...props} />
      <Transactions {...props} />
      <Diagram {...props} />
    </div>
  ) : (
    <p>Votre compte a été désactivé... Merci de contacter un administrateur</p>
  );
}

export default Home;
