import STATS_QUERY from "src/graphql/queries/stats.gql";
import MASTERNAUT_STATS from "src/graphql/queries/masternautStats.gql";

export async function getGeneralStats(client) {
  const data = await client.query({
    query: STATS_QUERY,
    fetchPolicy: "network-only",
  });

  return data.data.Transactions.stats;
}

export async function getMasternautStats(start, end, client) {
  const data = await client.query({
    query: MASTERNAUT_STATS,
    variables: {start, end},
    fetchPolicy: "network-only",
  });

  return data.data.FieldUserStats.between;
}
