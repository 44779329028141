import React from "react";
import {IdcardOutlined, UserOutlined} from "@ant-design/icons";
import {Tree, Popover} from "antd";
import {uuid} from "src/helpers";
import FontAwesome from "react-fontawesome";

const {TreeNode} = Tree;

class TreeBox extends React.PureComponent {
  render() {
    const {list, title} = this.props;

    return [
      <Tree key={uuid()} showIcon defaultExpandAll={false}>
        <TreeNode icon={<IdcardOutlined />} title={title} key="0-0">
          {list.map((item, index) => {
            const content = (
              <div>
                <p>
                  <FontAwesome name="phone" />
                  {item.phone}
                </p>
                <p>
                  <FontAwesome name="mobile" />
                  {item.mobile}
                </p>
                <p>
                  <FontAwesome name="at" />
                  {item.email}
                </p>
              </div>
            );
            return [
              <TreeNode
                icon={<UserOutlined />}
                title={
                  <Popover
                    key={item.id}
                    trigger="click"
                    content={content}
                    title="Contacts"
                  >
                    {item.firstname} {item.lastname}
                  </Popover>
                }
                key={`0-0-${index}`}
              />,
            ];
          })}
        </TreeNode>
      </Tree>,
    ];
  }
}

export default TreeBox;
