import ME_QUERY from "src/graphql/queries/me.gql";
import ADD_USER_MUTATION from "src/graphql/mutations/addUser.gql";
import LIST_FIELD_USERS_QUERY from "src/graphql/queries/listFieldUsers.gql";
import LIST_USERS_QUERY from "src/graphql/queries/listUsers.gql";
import GET_USER_QUERY from "src/graphql/queries/getUser.gql";
import UPDATE_USER_MUTATION from "src/graphql/mutations/updateUser.gql";

import {history} from "src/router/history";

export const login = () =>
  async function action(dispatch) {
    return dispatch({
      type: "LOGIN",
      fetchPolicy: "network-only",
    });
  };

export const getUserInfo = async client => {
  const data = await client.query({
    query: ME_QUERY,
    fetchPolicy: "network-only",
  });
  return data.data.Users.Me;
};

export const getUser = async (client, id) => {
  const data = await client.query({
    query: GET_USER_QUERY,
    fetchPolicy: "network-only",
    variables: {id},
  });
  return data.data.Users.get;
};

export const addUser = async (client, input) => {
  const data = await client.mutate({
    mutation: ADD_USER_MUTATION,
    variables: {input},
  });

  return data.data.User.signup;
};

export const updateUser = async (client, input) => {
  const data = await client.mutate({
    mutation: UPDATE_USER_MUTATION,
    variables: {input},
  });

  return data.data.User.update;
};

export const listUsers = async client => {
  const data = await client.query({
    query: LIST_USERS_QUERY,
    fetchPolicy: "network-only",
  });
  return data.data.Users.list;
};

export const listFieldUsers = async client => {
  const data = await client.query({
    query: LIST_FIELD_USERS_QUERY,
    fetchPolicy: "network-only",
  });
  return data.data.Users.listFieldUsers;
};

export const logout = () => {
  window.location.href = "/logout";
};
