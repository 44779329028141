import React from "react";
import {PlusOutlined} from "@ant-design/icons";
import {Upload, Modal} from "antd";

class SimplePictureUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: "",
      fileList: [],
    };
  }

  componentWillMount() {
    const {logo} = this.props || null;
    if (logo) {
      this.setState({
        previewVisible: false,
        previewImage: logo,
        fileList: [
          {
            uid: "-1",
            name: "no_logo",
            status: "done",
            url: logo,
          },
        ],
      });
    }
  }

  handleCancel = () => this.setState({previewVisible: false});

  handlePreview = data => {
    const url = data.url ? data.url : data.response.files.file.path;
    this.setState({
      previewImage: url,
      previewVisible: true,
    });
  };

  handleChange = ({fileList}) => {
    this.setState({fileList: fileList.slice(-1)});
    this.props.setLogo(fileList.slice(-1));
  };

  render() {
    const {previewVisible, previewImage, fileList} = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">
          {fileList[0].name === "no_logo" ? "Ajouter un logo" : "Modifier le logo"}
        </div>
      </div>
    );
    return (
      <div className="clearfix">
        <Upload
          action="https://api.lart2vendreimmobilier.com/upload"
          listType="picture-card"
          showUploadList={{showRemoveIcon: false, showPreviewIcon: true}}
          fileList={fileList}
          headers={{
            "custom-referer": "logo",
          }}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
        >
          {fileList.length > 1 ? null : uploadButton}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img
            alt="logo"
            style={{width: "100%", objectFit: "contain"}}
            src={previewImage}
          />
        </Modal>
      </div>
    );
  }
}

export default SimplePictureUploader;
