import React, {Component} from "react";
import {graphql} from "react-apollo";
import Helmet from "react-helmet";
import {fadeIn} from "react-animations";
import {StyleSheet, css} from "aphrodite";
import {
  MailOutlined,
  MobileOutlined,
  PhoneOutlined,
  ProfileOutlined,
  TagOutlined,
} from "@ant-design/icons";
import {Form} from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  Card,
  Select,
  Input,
  Switch,
  Button,
  message,
  DatePicker,
  InputNumber,
} from "antd";
import moment from "moment";
import FontAwesome from "react-fontawesome";
import {orderBy} from "lodash";

import {Loading, FormInput, showNotification} from "src/components/stateless";
import {addUser, updateUser, listUsers} from "src/actions/auth";
import ColorPicker from "components/stateless/colorPicker";

import GET_ROLES_TYPES from "src/graphql/queries/getRolesTypesList.gql";
import GET_USER_QUERY from "src/graphql/queries/getUser.gql";
import GET_PARENTS_LIST from "src/graphql/queries/listUsers.gql";

const FormItem = Form.Item;
const {Option} = Select;

const styles = StyleSheet.create({
  fadeIn: {
    animationName: fadeIn,
    animationDuration: "2s",
  },
});

// @graphql(GET_ROLES_TYPES, { options: () => ({ fetchPolicy: 'cache-and-network' }) })
class EditUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "edit",
      loading: true,
      currentId: this.props.match.params.id,
      confirmDirty: false,
      autoCompleteResult: [],
      selected: null,
      showResult: false,
      colorPickerVisible: false,
    };
  }

  componentDidMount = async () => {
    const {currentId, mode} = this.state;
    if (mode === "edit") {
      const user = await this.props.client.query({
        query: GET_USER_QUERY,
        fetchPolicy: "network-only",
        variables: {id: currentId},
      });
      const roles = await this.props.client.query({query: GET_ROLES_TYPES});
      const parentsList = await this.props.client.query({query: GET_PARENTS_LIST});

      console.log(parentsList);

      this.setState({
        loading: false,
        currentId,
        selected: user.data.Users.get,
        roleId: user.data.Users.get.role.id,
        diagramRole: user.data.Users.get.diagramRole,
        roles: roles.data.Roles.list,
        parents: orderBy(
          parentsList.data.Users.list.filter(
            u => u.id !== "c1237f10-84b6-4799-85c0-c25545d743ef"
          ),
          obj => obj.lastname
        ),
        parentId: user.data.Users.get.parent && user.data.Users.get.parent.id,
      });
    }
  };

  onChange = (elem, name) => {
    const {selected} = this.state;
    const newObj = {...selected};
    newObj[name] = elem.target ? elem.target.value : elem;
    this.setState({selected: newObj});
  };

  onRolechange = id => {
    this.setState({roleId: id});
  };

  onParentChange = id => {
    this.setState({parentId: id});
  };

  componentDidUpdate = async (nextProps, prevState) => {
    if (!nextProps) {
      return;
    }
    const {id} = nextProps.match.params;
    const {currentId} = prevState;

    if (id !== currentId) {
      this.setState({loading: true});
      const user = await this.props.client.query({
        query: GET_USER_QUERY,
        fetchPolicy: "network-only",
        variables: {id: currentId},
      });
      this.setState({loading: false, currentId: id, selected: user.data.Users.get});
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const {selected} = this.state;
    this.props.form.validateFields(async (err, fieldsValue) => {
      if (!err) {
        const data = {...fieldsValue};
        delete data.longitude;
        delete data.latitude;
        delete data.dateObtentionCertif;
        delete data.echeanceCertif;
        delete data.confirm;
        if (data.password === "") {
          delete data.password;
        }
        // update lat/lng
        if (fieldsValue.latitude && fieldsValue.longitude) {
          data.position = {
            latitude: parseFloat(fieldsValue.latitude),
            longitude: parseFloat(fieldsValue.longitude),
          };
        }
        // modify dates
        if (fieldsValue.dateObtentionCertif) {
          delete data.dateObtentionCertif;
          data.dateObtentionCertif = moment(fieldsValue.dateObtentionCertif).toDate();
        }
        if (fieldsValue.echeanceCertif) {
          delete data.echeanceCertif;
          data.echeanceCertif = moment(fieldsValue.echeanceCertif).toDate();
        }
        // fullname
        data.fullname = `${data.firstname} ${data.lastname}`;
        data.needTva = true;
        if (this.state.mode === "edit") {
          // set roleid (by default chargeaffaire for chargesaffaires)
          data.roleId = fieldsValue.roleId || selected.role.id;
          // add user id
          data.id = this.state.currentId;

          try {
            this.setState({loading: true});
            const res = await updateUser(this.props.client, data);
            showNotification("success", "Succès", "Utilisateur mis à jour avec succès");
            await listUsers(this.props.client);
            console.log("Mise à jour ok!", res);
            this.setState({loading: false, selected: res});
          } catch (error) {
            console.log(error);
            showNotification("error", "Erreur", "Erreur de mise à jour de l'utilisateur");
            this.setState({loading: false});
          }
        } else {
          // add client
          try {
            this.setState({loading: true});
            const res = await addUser(this.props.client, data);
            await listUsers(this.props.client);
            this.setState({
              loading: false,
              selected: res,
              showResult: true,
              results: res,
            });
          } catch (error) {
            console.log(error);
            showNotification("error", "Erreur", "Erreur de création du client");
            this.setState({loading: false});
          }
        }
      }
    });
  };

  handleConfirmBlur = e => {
    const {value} = e.target;
    this.setState({confirmDirty: this.state.confirmDirty || !!value});
  };

  compareToFirstPassword = (rule, value, callback) => {
    const {form} = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Les mots de passe ne correspondent pas!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const {form} = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], {force: true});
    }
    callback();
  };

  showSuccess = results => (
    <Card title="Résultat">
      <div className="container" align="middle">
        <FontAwesome
          name="check-circle"
          className={css(styles.fadeIn)}
          style={{marginBottom: 30, color: "#52c41a", fontSize: 64}}
        />
        <p>
          Utilisateur {results.firstname} {results.lastname} crée avec succès
        </p>
        <div className="container" align="middle" style={{display: "inline-block"}}>
          <Button
            onClick={() => {
              this.props.history.push("/users");
            }}
          >
            <FontAwesome name="address-book" />
            Retour à la liste des utilisateurs
          </Button>
          <Button
            onClick={() => {
              this.setState({showResult: false, selected: {}});
            }}
          >
            <FontAwesome name="user-plus" />
            Ajouter un autre utilisateur
          </Button>
        </div>
      </div>
    </Card>
  );

  onColorChange = color => {
    console.log(color);
    this.setState({selected: {...this.state.selected, hexColor: color}});
  };

  render() {
    const {loading, roles} = this.state;

    if (loading) {
      return <Loading />;
    }

    const {selected, mode, showResult} = this.state;

    if (showResult && selected) {
      return this.showSuccess(selected);
    }

    const current = this.props.user;

    const {getFieldDecorator} = this.props.form;

    // const disabledDate = curr => curr && curr < moment().endOf('day');
    const formItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 3, offset: 0},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 10},
      },
    };

    return (
      <>
        <Helmet
          title={`Art2vendreimmobiler ${
            mode === "add"
              ? "création utilisateur"
              : `${selected.firstname} ${selected.lastname}`
          }`}
          meta={[
            {
              name: `Art2vendreimmobiler ${
                mode === "add"
                  ? "création utilisateur"
                  : `${selected.firstname} ${selected.lastname}`
              }`,
            },
          ]}
        />
        <Row>
          <Col span={24}>
            <Card
              title={
                <h1 align="center" justify="center">
                  {mode === "add" ? (
                    "Créer un utilisateur"
                  ) : (
                    <span>
                      <FontAwesome name="user-edit" style={{marginRight: 20}} />
                      {selected.firstname} {selected.lastname}
                    </span>
                  )}
                </h1>
              }
            >
              {/* if ADMIN */}
              {current.role.name === "Admin" ? (
                <Row>
                  <Col span={24}>
                    <Card
                      title={
                        <h5>
                          <FontAwesome name="users-cog" />
                          Administration
                        </h5>
                      }
                      style={{marginBottom: 20}}
                    >
                      <div>
                        <Button
                          type="default"
                          onClick={() => this.props.history.push("/users")}
                        >
                          <span>
                            <FontAwesome name="users" />
                            Liste des utilisateurs
                          </span>
                        </Button>
                        <Button
                          style={{marginLeft: 10}}
                          type="default"
                          onClick={() =>
                            this.setState({
                              mode: "add",
                              selected: {
                                isDiagnostician: false,
                                disabled: false,
                              },
                            })
                          }
                        >
                          <span>
                            <FontAwesome name="user-plus" />
                            Ajouter un utilisateur
                          </span>
                        </Button>
                      </div>
                    </Card>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              <Row>
                <Col span={24}>
                  <Card
                    title={
                      <h5>
                        <FontAwesome name="user-circle" />
                        {mode === "add" ? "Créer" : "Modifier"}
                      </h5>
                    }
                    actions={[
                      <FontAwesome
                        onClick={this.handleSubmit}
                        style={{fontSize: 28, width: "100vh"}}
                        name="save"
                        title="Sauvegarder"
                      />,
                    ]}
                  >
                    <Form>
                      <FormItem {...formItemLayout} label="Titre">
                        {getFieldDecorator("title", {
                          initialValue: selected.title || "",
                          rules: [
                            {
                              required: true,
                              message: "Merci d'indiquer un titre, M. ou Mme. !",
                              whitespace: true,
                            },
                          ],
                        })(
                          <Select onChange={this.onUrgenceChange}>
                            <Option key="M." value="M.">
                              M.
                            </Option>
                            <Option key="Mme" value="Mme">
                              Mme
                            </Option>
                          </Select>
                        )}
                      </FormItem>

                      <FormItem {...formItemLayout} label="Nom">
                        {getFieldDecorator("lastname", {
                          initialValue: selected.lastname,
                          rules: [
                            {
                              required: true,
                              message: "Merci d'indiquer un nom de famille!",
                              whitespace: true,
                            },
                          ],
                        })(
                          <Input
                            onChange={e => this.onChange(e, "lastname")}
                            prefix={
                              <ProfileOutlined
                                style={{
                                  color: "rgba(0,0,0,.25)",
                                }}
                              />
                            }
                          />
                        )}
                      </FormItem>

                      <FormItem {...formItemLayout} label="Prénom">
                        {getFieldDecorator("firstname", {
                          initialValue: selected.firstname,
                          rules: [
                            {
                              required: true,
                              message: "Merci d'indiquer un prénom!",
                              whitespace: true,
                            },
                          ],
                        })(
                          <Input
                            onChange={e => this.onChange(e, "firstname")}
                            prefix={
                              <ProfileOutlined
                                style={{
                                  color: "rgba(0,0,0,.25)",
                                }}
                              />
                            }
                          />
                        )}
                      </FormItem>

                      <FormItem {...formItemLayout} label="email">
                        {getFieldDecorator("email", {
                          initialValue: selected.email,
                          rules: [
                            {
                              required: true,
                              type: "email",
                              message: "Merci d'indiquer un email valide!",
                              whitespace: true,
                            },
                          ],
                        })(
                          <Input
                            onChange={e => this.onChange(e, "email")}
                            prefix={
                              <MailOutlined
                                style={{
                                  color: "rgba(0,0,0,.25)",
                                }}
                              />
                            }
                          />
                        )}
                      </FormItem>

                      <FormItem {...formItemLayout} label="Mot de passe" hasFeedback>
                        {getFieldDecorator("password", {
                          rules: [
                            {
                              required: false,
                              message: "Merci d`'indiquer un mot de passe!",
                            },
                            {
                              validator: this.validateToNextPassword,
                            },
                          ],
                        })(<Input.Password autocomplete="new-password" />)}
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label="Confirmer le mot de passe"
                        hasFeedback
                      >
                        {getFieldDecorator("confirm", {
                          rules: [
                            {
                              required: false,
                              message: "Merci de confirmer votre mot de passe!",
                            },
                            {
                              validator: this.compareToFirstPassword,
                            },
                          ],
                        })(
                          <Input.Password
                            onBlur={this.handleConfirmBlur}
                            autocomplete="new-password"
                          />
                        )}
                      </FormItem>

                      {current.role.name === "Admin" && (
                        <FormItem {...formItemLayout} label="Rôle">
                          {getFieldDecorator("roleId", {
                            initialValue: this.state.roleId || "",
                            rules: [
                              {
                                required: true,
                                message: "Merci de sélectionner un rôle!",
                                whitespace: true,
                              },
                            ],
                          })(
                            <Select onChange={this.onRolechange}>
                              {roles.map(ac => (
                                <Option key={ac.id} value={ac.id}>
                                  {ac.name}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </FormItem>
                      )}

                      {selected.id !== "c1237f10-84b6-4799-85c0-c25545d743ef" &&
                        selected.id !== "dd4c7ea4-d167-4456-90da-e7adb8f607f6" &&
                        this.state.roleId !== "698cdef2-618d-457d-b83a-6cd0f2967a13" && (
                          <FormItem {...formItemLayout} label="Parent">
                            {getFieldDecorator("parentId", {
                              initialValue: this.state.parentId || "",
                              rules: [
                                {
                                  required: true,
                                  message: "Merci de sélectionner un parent!",
                                  whitespace: true,
                                },
                              ],
                            })(
                              <Select onChange={this.onParentChange}>
                                {this.state.parents.map(ac => (
                                  <Option key={ac.id} value={ac.id}>
                                    {ac.firstname} {ac.lastname}
                                  </Option>
                                ))}
                              </Select>
                            )}
                          </FormItem>
                        )}

                      <FormItem {...formItemLayout} label="Fonction">
                        {getFieldDecorator("function", {
                          initialValue: selected.function,
                          rules: [
                            {
                              required: true,
                              message: "Merci d'indiquer une fonction !",
                              whitespace: true,
                            },
                          ],
                        })(
                          <Input
                            onChange={e => this.onChange(e, "function")}
                            prefix={
                              <TagOutlined
                                style={{
                                  color: "rgba(0,0,0,.25)",
                                }}
                              />
                            }
                          />
                        )}
                      </FormItem>

                      <FormInput
                        formItemLayout={{...formItemLayout}}
                        form={this.props.form || null}
                        mask="11 11 11 11 11"
                        value={selected.phone || ""}
                        prefix={
                          <PhoneOutlined
                            style={{
                              color: "rgba(0,0,0,.25)",
                            }}
                          />
                        }
                        label="Téléphone fixe"
                        name="phone"
                        rules={[
                          {
                            pattern: new RegExp(
                              "^[0-9](1|2|3|4|5|8|9) [0-9][0-9] [0-9][0-9] [0-9][0-9] [0-9][0-9]$"
                            ),
                            message: "Mauvais format de numéro... !",
                            required: false,
                            whitespace: true,
                          },
                        ]}
                      />

                      <FormInput
                        formItemLayout={{...formItemLayout}}
                        form={this.props.form || null}
                        mask="11 11 11 11 11"
                        prefix={
                          <MobileOutlined
                            style={{
                              color: "rgba(0,0,0,.25)",
                            }}
                          />
                        }
                        name="mobile"
                        label="Téléphone mobile"
                        value={selected.mobile || ""}
                        rules={[
                          {
                            pattern: new RegExp(
                              "^0(6|7) [0-9][0-9] [0-9][0-9] [0-9][0-9] [0-9][0-9]$"
                            ),
                            message: "Mauvais format de numéro... !",
                            required: false,
                            whitespace: true,
                          },
                        ]}
                      />
                      <FormItem {...formItemLayout} label="Assujeti à la TVA">
                        {getFieldDecorator("needTva", {
                          initialValue: true,
                        })(
                          <Switch
                            disabled
                            onChange={e => this.onChange(e, "needTva")}
                            checkedChildren="Oui"
                            unCheckedChildren="Non"
                            defaultChecked={true}
                          />
                        )}
                      </FormItem>

                      {current.role.name === "Admin" && (
                        <FormItem {...formItemLayout} label="Désactivé">
                          {/* <Tooltip title="Utile pour juste désactiver le compte sans le supprimer ">
                              <Icon style={{position:relative, top:'-2px',marginTop:-4,marginRight:5}} type="question-circle-o" />
                          </Tooltip> */}
                          {getFieldDecorator("disabled", {
                            initialValue: selected.disabled,
                          })(
                            <Switch
                              onChange={e => this.onChange(e, "disabled")}
                              checkedChildren="Oui"
                              unCheckedChildren="Non"
                              checked={selected.disabled}
                            />
                          )}
                        </FormItem>
                      )}
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const WrappedClientForm = Form.create()(EditUsers);

export default WrappedClientForm;
