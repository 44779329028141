import {notification} from "antd";

const showNotification = (type, title, message) => {
  return notification[type]({
    type,
    message: title,
    description: message,
    duration: type !== "error" ? 3 : 5,
    placement: "topRight",
  });
};

export default showNotification;
