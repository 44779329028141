import React, {Component} from "react";
import {
  Row,
  Col,
  Card,
  Select,
  Input,
  Icon,
  Switch,
  InputNumber,
  Tooltip,
  AutoComplete,
} from "antd";
import FontAwesome from "react-fontawesome";
import {Form} from "@ant-design/compatible";

import {Loading, showNotification} from "src/components/stateless";
import {
  getCurrentTransaction,
  updateTransaction,
  addTransaction,
  updateTransactionsList,
} from "src/actions/transactions";

import {getPrescriptors, searchRepresentative} from "src/actions/users";

const FormItem = Form.Item;
const {Option} = AutoComplete;

class EditTransactions extends Component {
  constructor(props) {
    super(props);
    this.addDrForm = React.createRef();
    this.state = {
      mode: this.props.currentId ? "edit" : "add",
      loading: true,
      currentId: this.props.currentId,
      name: null,
      hasPrescriptor: null,
      inputPrescriptor: null,
      outputPrescriptor: null,
      representativeInput: this.props.user,
      selectedRepresentative: this.props.user.id,
      inputRecommandator: null,
      outputRecommandator: null,
      prescriptors: [],
      representatives: [this.props.user],
    };
  }

  componentDidMount = async () => {
    const {currentId, mode} = this.state;
    const prescriptors = await getPrescriptors(this.props.client);
    if (mode === "edit") {
      try {
        const data = await getCurrentTransaction(currentId, this.props.client);
        const representatives = [data.representativeInput || this.props.user];
        if (data.inputPrescriptor) {
          representatives.push(data.inputPrescriptor);
        }

        if (data.outputPrescriptor) {
          representatives.push(data.outputPrescriptor);
        }
        if (data.inputRecommandator) {
          representatives.push(data.inputRecommandator);
        }
        if (data.outputRecommandator) {
          representatives.push(data.outputRecommandator);
        }
        this.setState({
          loading: false,
          ...data,
          prescriptors,
          representatives,
          selectedRepresentative: data.representativeInput.id || null,
        });
      } catch (err) {
        this.setState({
          loading: false,
          data: [],
        });
      }
    } else {
      this.setState({loading: false, prescriptors});
    }
  };

  componentWillReceiveProps = async nextProps => {
    if (!nextProps) {
      return;
    }

    const id = this.state.currentId;
    const {currentId} = nextProps;

    console.log("edit props", nextProps);

    if (currentId && (!id || id !== currentId)) {
      this.setState({loading: true});
      const data = await getCurrentTransaction(currentId, this.props.client);
      console.log(data);
      const representatives = [data.representativeInput || this.props.user];
      if (data.inputPrescriptor) {
        representatives.push(data.inputPrescriptor);
      }

      if (data.outputPrescriptor) {
        representatives.push(data.outputPrescriptor);
      }
      if (data.inputRecommandator) {
        representatives.push(data.inputRecommandator);
      }
      if (data.outputRecommandator) {
        representatives.push(data.outputRecommandator);
      }
      this.setState({
        ...data,
        loading: false,
        mode: this.props.currentId ? "edit" : "add",
        currentId,
        selectedRepresentative: data.representativeInput.id || null,
        representatives,
      });
    } else if (!currentId) {
      this.setState({
        loading: false,
        commissionAmount: null,
        nettyId: null,
        currentId: null,
        mode: "add",
      });
    }
  };

  onChange = (elem, name) => {
    if (!elem) {
      return;
    }
    const value = elem.target ? elem.target.value : elem;
    console.log(elem, name, value);
    this.setState({[name]: value});
  };

  onSelect = id => {
    if (!id) {
      return;
    }
    this.setState({
      selectedRepresentative: id,
      representativeInput: this.state.representatives.find(r => r.id === id),
    });
  };

  onRepresentativeSearch = async e => {
    if (!e || (e && e.split("").length < 2)) {
      this.setState({representatives: []});
      return;
    }
    try {
      const users = await searchRepresentative(e, this.props.client);
      console.log(users);
      this.setState({representatives: users});
    } catch (err) {
      console.log(err);
    }
  };

  renderOption = item => {
    return (
      <Option
        key={item.id}
        //text={`${item.firstname} ${item.lastname}`}
        value={`${item.firstname} ${item.lastname}`}
        className="map-search-item"
      >
        <div className="global-search-item">
          <span className="global-search-item-desc">{`${item.firstname} ${item.lastname}`}</span>
        </div>
      </Option>
    );
  };

  prepareSubmit = async e => {
    e.preventDefault();
    this.handleSubmit();
  };

  handleSubmit = (e, drData = null) => {
    e && e.preventDefault();
    const data = {};
    this.props.form.validateFields(async (err, fieldsValue) => {
      if (!err) {
        const values = {
          ...data,
          userId: this.props.user.id,
          ...fieldsValue,
        };
        if (this.state.mode === "edit") {
          try {
            this.setState({loading: true});
            values.id = this.state.currentId;
            const res = await updateTransaction(values, this.props.client);
            showNotification("success", "Succès", "Transaction mis à jour avec succès");
            console.log("Mise à jour ok!", res);
            this.setState({loading: false, ...res});
          } catch (error) {
            showNotification(
              "error",
              "Erreur",
              "Erreur de mise à jour de la transaction"
            );
          }
        } else {
          // add transaction
          try {
            this.setState({loading: true});
            const res = await addTransaction(values, this.props.client);
            showNotification("success", "Succès", "Transaction crée avec succès");
            this.setState({loading: false, ...res});
          } catch (error) {
            showNotification("error", "Erreur", "Erreur de création de la transaction");
          }
        }
        this.props.form.resetFields();
        this.setState({loading: false});
      }
    });
  };

  onHasPrescriptorChange = value => {
    this.setState({
      hasPrescriptor: value,
    });
  };

  onHasRecommandationChange = value => {
    this.setState({
      hasRecommandation: value,
    });
  };

  render() {
    const {
      nettyId,
      commissionAmount,
      hasPrescriptor,
      inputPrescriptor,
      outputPrescriptor,
      representativeInput,
      prescriptors,
      representatives,
      selectedRepresentative,
      hasRecommandation,
      inputRecommandator,
      outputRecommandator,
      loading,
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    const {getFieldDecorator} = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 4, offset: 0},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 6},
      },
    };

    return (
      <>
        <Row>
          <Col span={24}>
            <Card
              title={
                <h5>
                  <FontAwesome name="user-circle" />
                  Informations
                </h5>
              }
              actions={[
                <FontAwesome
                  onClick={this.prepareSubmit}
                  style={{fontSize: 28, width: "100vh"}}
                  name="save"
                  title="Sauvegarder"
                />,
              ]}
            >
              <Form>
                <FormItem {...formItemLayout} label="Id du bien netty">
                  {getFieldDecorator("nettyId", {
                    initialValue: nettyId || null,
                    rules: [
                      {
                        required: true,
                        message: "Merci d'indiquer l'id du bien sur netty.fr",
                        whitespace: true,
                      },
                    ],
                  })(
                    <Input
                      onChange={e => this.onChange(e, "nettyId")}
                      prefix={
                        <FontAwesome
                          name="building"
                          style={{
                            color: "rgba(0,0,0,.25)",
                          }}
                        />
                      }
                    />
                  )}
                </FormItem>

                <FormItem {...formItemLayout} label="commission">
                  {getFieldDecorator("commissionAmount", {
                    initialValue: commissionAmount || null,
                    rules: [
                      {
                        required: true,
                        type: "number",
                        message: "Merci d'indiquer votre commission",
                        whitespace: false,
                      },
                    ],
                  })(
                    <InputNumber
                      style={{minWidth: 200}}
                      min={0}
                      max={100000}
                      onChange={e => this.onChange(e, "commissionAmount")}
                      formatter={value => `${value} €`}
                    />
                  )}
                </FormItem>

                <FormItem {...formItemLayout} label="Mandataire d'origine">
                  {getFieldDecorator("representativeInputId", {
                    initialValue: selectedRepresentative,
                    rules: [
                      {
                        required: true,
                        message: "Merci d'indiquer le mandataire original de ce bien",
                        whitespace: false,
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{width: 200}}
                      placeholder="Entrer un nom de famille..."
                      optionFilterProp="children"
                      onChange={this.onSelect}
                      onSearch={this.onRepresentativeSearch}
                    >
                      {representatives.map(r => (
                        <Option key={r.id} text={`${r.firstname} ${r.lastname}`}>
                          {`${r.firstname} ${r.lastname}`}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>

                <FormItem {...formItemLayout} label="Prescripteur">
                  {getFieldDecorator("hasPrescriptor", {
                    initialValue: hasPrescriptor,
                  })(
                    <Switch
                      //onChange={e => this.onChange(e, "hasPrescriptor")}
                      onClick={e => this.onHasPrescriptorChange(e, "hasPrescriptor")}
                      checkedChildren="Oui"
                      unCheckedChildren="Non"
                      defaultChecked={hasPrescriptor}
                    />
                  )}
                </FormItem>

                {hasPrescriptor && (
                  <div>
                    <FormItem {...formItemLayout} label="Entrant">
                      {getFieldDecorator("inputPrescriptorId", {
                        initialValue: inputPrescriptor && inputPrescriptor.id,
                      })(
                        <Select
                          onChange={(e, elem) => this.onChange(e, "inputPrescriptor")}
                        >
                          {prescriptors.map(user => (
                            <Option
                              key={user.id}
                              text={`${user.firstname} ${user.lastname}`}
                            >
                              {`${user.firstname} ${user.lastname}`}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>

                    <FormItem {...formItemLayout} label="Sortant">
                      {getFieldDecorator("outputPrescriptorId", {
                        initialValue: outputPrescriptor && outputPrescriptor.id,
                      })(
                        <Select
                          onChange={(e, elem) => this.onChange(e, "outputPrescriptor")}
                        >
                          {prescriptors.map(user => (
                            <Option
                              key={user.id}
                              text={`${user.firstname} ${user.lastname}`}
                            >
                              {`${user.firstname} ${user.lastname}`}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                  </div>
                )}

                <FormItem {...formItemLayout} label="Recommandation">
                  {getFieldDecorator("hasRecommandation", {
                    initialValue: hasRecommandation,
                  })(
                    <Switch
                      //onChange={e => this.onChange(e, "hasRecommandation")}
                      onClick={e =>
                        this.onHasRecommandationChange(e, "hasRecommandation")
                      }
                      checkedChildren="Oui"
                      unCheckedChildren="Non"
                      defaultChecked={hasRecommandation}
                    />
                  )}
                </FormItem>

                {hasRecommandation && (
                  <div>
                    <FormItem {...formItemLayout} label="Entrant">
                      {getFieldDecorator("inputRecommandatorId", {
                        initialValue: inputRecommandator && inputRecommandator.id,
                      })(
                        <Select
                          showSearch
                          style={{width: 200}}
                          placeholder="Entrer un nom de famille ou un prénom..."
                          optionFilterProp="children"
                          onSearch={this.onRepresentativeSearch}
                          onChange={(e, elem) => this.onChange(e, "inputRecommandator")}
                        >
                          {representatives.map(user => (
                            <Option
                              key={user.id}
                              text={`${user.firstname} ${user.lastname}`}
                            >
                              {`${user.firstname} ${user.lastname}`}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>

                    <FormItem {...formItemLayout} label="Sortant">
                      {getFieldDecorator("outputRecommandatorId", {
                        initialValue: outputRecommandator && outputRecommandator.id,
                      })(
                        <Select
                          showSearch
                          style={{width: 200}}
                          placeholder="Entrer un nom de famille ou un prénom..."
                          optionFilterProp="children"
                          onSearch={this.onRepresentativeSearch}
                          onChange={(e, elem) => this.onChange(e, "outputRecommandator")}
                        >
                          {representatives.map(user => (
                            <Option
                              key={user.id}
                              text={`${user.firstname} ${user.lastname}`}
                            >
                              {`${user.firstname} ${user.lastname}`}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                  </div>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const EditTransactionsForm = Form.create()(EditTransactions);

export default EditTransactionsForm;
