// may contain pieces of https://github.com/insin/react-maskedinput/blob/master/src/index.js
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Form} from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {Input} from "antd";
import InputMask from "inputmask-core";

const FormItem = Form.Item;

const KEYCODE_Z = 90;
const KEYCODE_Y = 89;

function isUndo(e) {
  return (e.ctrlKey || e.metaKey) && e.keyCode === (e.shiftKey ? KEYCODE_Y : KEYCODE_Z);
}

function isRedo(e) {
  return (e.ctrlKey || e.metaKey) && e.keyCode === (e.shiftKey ? KEYCODE_Z : KEYCODE_Y);
}

function getSelection(el) {
  let start;
  let end;
  if (el.selectionStart !== undefined) {
    start = el.selectionStart;
    end = el.selectionEnd;
  } else {
    try {
      el.focus();
      const rangeEl = el.createTextRange();
      const clone = rangeEl.duplicate();

      rangeEl.moveToBookmark(document.selection.createRange().getBookmark());
      clone.setEndPoint("EndToStart", rangeEl);

      start = clone.text.length;
      end = start + rangeEl.text.length;
    } catch (e) {
      /* not focused or not visible */
    }
  }

  return {start, end};
}

function setSelection(el, selection) {
  try {
    if (el.selectionStart !== undefined) {
      el.focus();
      el.setSelectionRange(selection.start, selection.end);
    } else {
      el.focus();
      const rangeEl = el.createTextRange();
      rangeEl.collapse(true);
      rangeEl.moveStart("character", selection.start);
      rangeEl.moveEnd("character", selection.end - selection.start);
      rangeEl.select();
    }
  } catch (e) {
    /* not focused or not visible */
  }
}

class FormInputComponent extends Component {
  static propTypes = {
    mask: PropTypes.string,

    formatCharacters: PropTypes.object,
    placeholderChar: PropTypes.string,

    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    rules: PropTypes.array,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    value: "",
    rules: [],
  };

  componentWillMount() {
    if (!this.props.mask) return null;
    const options = {
      pattern: this.props.mask,
      value: this.props.value !== "" ? this.props.value : this.props.initialValue,
      formatCharacters: this.props.formatCharacters,
    };

    if (this.props.placeholderChar) {
      options.placeholderChar = this.props.placeholderChar;
    }
    this.mask = new InputMask(options);
  }

  componentWillUpdate(nextProps, nextState) {
    if (!this.props.mask) return null;
    if (nextProps.mask !== this.props.mask) {
      this.datePattern(nextProps);
    }
  }

  componentDidUpdate = (nextProps, prevState) => {
    if (!this.props.mask) return null;
    if (this.props.mask !== nextProps.mask && this.props.value !== nextProps.mask) {
      // if we get a new value and a new mask at the same time
      // check if the mask.value is still the initial value
      // - if so use the nextProps value
      // - otherwise the `this.mask` has a value for us (most likely from paste action)
      if (this.mask.getValue() === this.mask.emptyValue) {
        this.mask.setPattern(nextProps.mask, {value: nextProps.value});
      } else {
        this.mask.setPattern(nextProps.mask, {
          value: this.mask.getRawValue(),
        });
      }
    } else if (this.props.mask !== nextProps.mask) {
      this.mask.setPattern(nextProps.mask, {value: this.mask.getRawValue()});
    } else if (this.props.value !== nextProps.value) {
      this.mask.setValue(nextProps.value);
    }
  };

  componentDidUpdate(prevProps) {
    if (!this.props.mask) return null;
    if (prevProps.mask !== this.props.mask && this.mask.selection.start) {
      this.dateInputSelection();
    }
  }

  datePattern(props) {
    console.log(this.mask, props);
    this.mask.setPattern(props.mask, {
      value: this.mask.getRawValue(),
      selection: getSelection(this.input),
    });
  }

  dateMaskSelection() {
    this.mask.selection = getSelection(this.input);
  }

  dateInputSelection() {
    setSelection(this.input, this.mask.selection);
  }

  _onChange = e => {
    // console.log('onChange', JSON.stringify(getSelection(this.input)), e.target.value)

    const maskValue = this.mask.getValue();
    const incomingValue = e.target.value;
    if (incomingValue !== maskValue) {
      // only modify mask if form contents actually changed
      this.dateMaskSelection();
      this.mask.setValue(incomingValue); // write the whole updated value into the mask
      e.target.value = this._getDisplayValue(); // update the form with pattern applied to the value
      this.dateInputSelection();
    }

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  _onKeyDown = e => {
    // console.log('onKeyDown', JSON.stringify(getSelection(this.input)), e.key, e.target.value)

    if (isUndo(e)) {
      e.preventDefault();
      if (this.mask.undo()) {
        e.target.value = this._getDisplayValue();
        this.dateInputSelection();
        if (this.props.onChange) {
          this.props.onChange(e);
        }
      }
      return;
    }
    if (isRedo(e)) {
      e.preventDefault();
      if (this.mask.redo()) {
        e.target.value = this._getDisplayValue();
        this.dateInputSelection();
        if (this.props.onChange) {
          this.props.onChange(e);
        }
      }
      return;
    }

    if (e.key === "Backspace") {
      e.preventDefault();
      this.dateMaskSelection();
      if (this.mask.backspace()) {
        const value = this._getDisplayValue();
        e.target.value = value;
        if (value) {
          this.dateInputSelection();
        }
        if (this.props.onChange) {
          this.props.onChange(e);
        }
      }
    }
  };

  _onKeyPress = e => {
    // console.log('onKeyPress', JSON.stringify(getSelection(this.input)), e.key, e.target.value)

    // Ignore modified key presses
    // Ignore enter key to allow form submission
    if (e.metaKey || e.altKey || e.ctrlKey || e.key === "Enter") {
      return;
    }

    e.preventDefault();
    this.dateMaskSelection();
    if (this.mask.input(e.key || e.data)) {
      e.target.value = this.mask.getValue();
      this.dateInputSelection();
      if (this.props.onChange) {
        this.props.onChange(e);
      }
    }
  };

  _onPaste = e => {
    // console.log('onPaste', JSON.stringify(getSelection(this.input)), e.clipboardData.getData('Text'), e.target.value)

    e.preventDefault();
    this.dateMaskSelection();
    // getData value needed for IE also works in FF & Chrome
    if (this.mask.paste(e.clipboardData.getData("Text"))) {
      e.target.value = this.mask.getValue();
      // Timeout needed for IE
      setTimeout(() => this.dateInputSelection(), 0);
      if (this.props.onChange) {
        this.props.onChange(e);
      }
    }
  };

  _getDisplayValue() {
    const value = this.mask.getValue();
    return value === this.mask.emptyValue ? "" : value;
  }

  _keyPressPropName() {
    if (typeof navigator !== "undefined") {
      return navigator.userAgent.match(/Android/i) ? "onBeforeInput" : "onKeyPress";
    }
    return "onKeyPress";
  }

  _getEventHandlers() {
    return {
      onChange: this._onChange,
      onKeyDown: this._onKeyDown,
      onPaste: this._onPaste,
      [this._keyPressPropName()]: this._onKeyPress,
    };
  }

  focus() {
    this.input.focus();
  }

  blur() {
    this.input.blur();
  }

  getInputProps = () => {
    const ref = r => {
      this.input = r.input;
    };
    const maxLength = this.mask.pattern.length;
    const value = this._getDisplayValue();
    const eventHandlers = this._getEventHandlers();
    const {size = maxLength, placeholder = this.mask.emptyValue} = this.props;

    const {placeholderChar, formatCharacters, ...cleanedProps} = this.props; // eslint-disable-line no-unused-vars
    const props = {
      ...cleanedProps,
      ...eventHandlers,
      ref,
      maxLength,
      placeholder,
    };
    delete props.value;
    return props;
  };

  render() {
    const {form, name, label, rules, value, placeholder, mask} = this.props;
    const inputProps = mask ? this.getInputProps() : {};

    return (
      <FormItem {...inputProps.formItemLayout} label={label || placeholder}>
        {form.getFieldDecorator(name, {
          onChange: this.getValueFromEvent,
          validateTrigger: "onBlur",
          initialValue: value,
          rules,
        })(<Input {...inputProps} placeholder={placeholder || label} />)}
      </FormItem>
    );
  }
}

export const FormInput = FormInputComponent;
