import React, { useState, useEffect } from "react";
import { render } from "react-dom";
import "./styles.css";
import { OrgChartComponent } from "./OrgChart";
import { Row, Col, Card } from "antd";
import * as d3 from "d3";
import FontAwesome from "react-fontawesome";
import { orderBy } from "lodash";
import { getChildrensOfId, getUsersList } from "src/actions/users";
import gql from "graphql-tag";
import { getUser } from "src/actions/users";

import useWindowDimensions from "../../hooks/useWindowDimensions";

export const Diagram = (props) => {
  const [data, setData] = useState(null);

  function onNodeClick(nodeId) {
    console.log("d3", d3.event);
    alert("clicked " + nodeId);
  }

  useEffect(() => {
    let list = [];
    async function removeParent(id, list) {
      const user = props.user;
      console.log(
        `removing parent ${user.firstname} ${user.lastname}, parent: ${user.parent && user.parent.id
        }`,
      );
      if (user.parent) {
        list = list.filter(
          (u) => u.id !== id && (u.parent && u.parent.id) !== id,
        );
        list = await removeParent(user.parent.id, list);
      } else if (user.firstname === "A2V") {
        list = list.filter(
          (u) => u.id !== user.id && (u.parent && u.parent.id) !== id,
        );
        // remove all childs
      } else {
        list = [user];
      }
      return list.filter((u) => {
        if (
          (u.parent && u.parent.id && list.find((f) => f.id === u.parent.id)) ||
          !u.parent
        ) {
          return u;
        }
      });
    }

    async function buildUsers() {
      const users = await getUsersList(props.client);
      const user = props.user;
      let list = [];
      if (props.user.role.name === "Admin") {
        return users;
      } else {
        const data = await getChildrensOfId(user.id, props.client);
        console.log(data, users);
        list = [{ ...user, parent: null }];
        list.push(...users.filter((u) => data.includes(u.id)));

        return list;
      }
    }

    async function buildList() {
      let list = await buildUsers();
      console.log("origin list", list, typeof list);
      if (!Array.isArray(list)) {
        list = [list];
      }
      console.log("after build", list);
      const data = list
        .filter(
          (u) =>
            u.id !== "c1237f10-84b6-4799-85c0-c25545d743ef" &&
            u.role.id !== "698cdef2-618d-457d-b83a-6cd0f2967a13" &&
            !u.disabled,
        )
        .map((user) => {
          let nodeImage;
          user.childrens = user.childrens.length
            ? user.childrens.filter((e) => !e.disabled)
            : [];
          if (user.firstname === "A2V") {
            nodeImage = require("../../assets/img/logo.png");
          } else if (user.lastname === "Ropert") {
            nodeImage =
              "https://img.netty.immo/ropert/assets/nego/001_l.jpg?1581941155";
          } else {
            nodeImage =
              "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg";
          }
          return {
            nodeId: user.id,
            parentNodeId: user.parent && user.parent.id,
            expanded: true,
            width: 342,
            height: 146,
            borderWidth: 1,
            borderRadius: 5,
            initialZoom: 1,
            borderColor: { red: 0, green: 0, blue: 0, alpha: 0.5 },
            backgroundColor:
              user.childrens.length > 0
                ? { red: 250, green: 173, blue: 20, alpha: 1 }
                : { red: 51, green: 182, blue: 208, alpha: 1 },
            nodeImage: {
              url: nodeImage,
              width: 100,
              height: 100,
              centerTopDistance: 0,
              centerLeftDistance: 0,
              cornerShape: "CIRCLE",
              shadow: false,
              borderWidth: 0,
              borderColor: { red: 0, green: 0, blue: 0, alpha: 1 },
            },
            nodeIcon: { icon: "https://to.ly/1yZnX", size: 30 },
            template: `
          <div>
            <div style="margin-left:70px;margin-top:10px;font-size:20px;font-weight:bold;">${user.numId !== 0 ? user.numId + " | " : ""
              }${user.firstname} ${user.lastname}</div>\
            <div style="margin-left:70px;margin-top:3px;font-size:16px;">
              ${user.function}
            </div>
            <div style="margin-left:70px;margin-top:3px;font-size:14px;">

            </div>
            ${user.childrens.length
                ? `<div style="margin-left:20px;    margin-top:15px;    font-size:13px;    position:absolute;bottom:5px;">
              <img src="" width="30"><i class="fas fa-network-wired"></i></img>
              <div style="margin-top:5px">${user.childrens.length > 1
                  ? `${user.childrens.length} enfants directs`
                  : `${user.childrens.length} enfant direct`
                }</div>
            </div>`
                : ""
              }
            <div style="position:absolute;bottom:5px;right:20px;">
              Gain Total ${user.needTva ? "(HT)" : ""}:
              ${user.needTva
                ? user.earnings
                  .reduce(
                    (c, n) =>
                    (c += parseFloat(
                      parseFloat(n.amount / (1 + 20 / 100)).toFixed(2),
                    )),
                    0,
                  )
                  .toFixed(2)
                : user.earnings
                  .reduce(
                    (c, n) =>
                      (c += parseFloat(parseFloat(n.amount).toFixed(2))),
                    0,
                  )
                  .toFixed(2)
              } €
            </div>
          </div>
          `,
            connectorLineColor: { red: 0, green: 0, blue: 0, alpha: 0.5 },
            connectorLineWidth: 5,
            dashArray: "",
            directSubordinates: user.childrens.length,
            totalSubordinates: 1515,
          };
        });
      console.log("DATA", data);
      setData(data);
    }
    buildList();

    // live update
    props.client
      .subscribe({
        query: gql`
          subscription {
            statsUpdated {
              transactions
            }
          }
        `,
        variables: {},
      })
      .subscribe(async (data, client) => {
        try {
          console.log("updated stats from websocket", data.data.statsUpdated);
          buildList();
        } catch (err) {
          console.log(err);
        }
      });
    // });
  }, []);

  const { height, width } = useWindowDimensions();

  return (
    <div>
      <Row
        type="flex"
        justify="space-around"
        align="middle"
        alignItems="middle"
        style={{ marginTop: 20, height, overflow: "hidden" }}
      >
        <Col span={24} style={{ height, width }}>
          <Card
            style={{ height, width }}
            title={
              <h5>
                <FontAwesome name="sitemap" style={{ color: "#1890ff" }} />
                Organigramme
              </h5>
            }
          >
            <OrgChartComponent
              onNodeClick={onNodeClick}
              data={data}
              height={height - 85}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
