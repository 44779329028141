import React from "react";
import {Tree, Row, Col, Spin, Menu, message, Divider} from "antd";
import prettyFileIcons from "pretty-file-icons";
import ImageLoader from "react-load-image";
import ImageZoom from "react-medium-image-zoom";
import {downloadFile} from "src/helpers";
import {loadProgressBar} from "axios-progress-bar";
import FontAwesome from "react-fontawesome";
// const isBrowser = typeof window !== 'undefined'; save for later
import {ContextMenu, ContextMenuTrigger} from "react-contextmenu";
import {deleteFile} from "src/actions/files";
import showNotification from "../notifications";
import PdfDrawer from "src/components/pdfDrawer";
import ManualUploader from "components/stateless/ManualUploader";

const {TreeNode, DirectoryTree} = Tree;

const importAll = require =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {});

const Images = importAll(
  require.context(
    "../../../../node_modules/pretty-file-icons/svg",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

class FileTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: props.files || [],
      filePath: null,
      isImage: false,
      showMenu: false,
      selected: null,
      checkedKeys: [],
      showUploader: this.props.showUploader || true,
    };
  }

  componentDidMount() {
    loadProgressBar();
  }

  componentWillReceiveProps = nextProps => {
    const files = this.state;
    const nextFiles = nextProps.files;

    if (files !== nextFiles) {
      this.setState({files: nextFiles});
    }
  };

  onSelect = async (e, obj) => {
    const {node} = obj;
    console.log("filtree obj", node);
    const {name, type, size, path, docType} = node.props;
    const filePath = path;
    console.log(type);
    if (!type) return; // when click on a folder...
    const isImage = type.includes("image");
    if (!isImage && !type.includes("pdf")) {
      await downloadFile(filePath, name, type, size);
    } else {
      this.setState({
        isImage,
        type,
        name,
        filePath,
        docType,
        selected: node.props,
      });
    }
  };

  handleEvent = info => {
    console.log("selected", info);
    this.setState({selected: info});
  };

  onExpand = () => {
    console.log("Trigger Expand");
  };

  onCheck = (checkedKeys, info) => {
    const list = info.checkedNodes
      .filter(n => {
        if (!n.props.id) {
          return false; // skip
        }
        return true;
      })
      .map(n => n.props.id);
    this.setState({checkedKeys: list});
  };

  onDownloadfile = async e => {
    const {name, type, size, path} = this.state.selected.node.props;
    await downloadFile(path, name, type, size);
  };

  onPdfClose = () => {
    this.setState({
      name: null,
      filePath: null,
      type: null,
    });
  };

  onRemoveFile = async e => {
    const {checkedKeys, files} = this.state;
    console.log(checkedKeys);
    try {
      message.loading("Suppression des fichiers en cours...");
      await deleteFile(checkedKeys, this.props.client);
      message.destroy();
      this.setState({files: files.filter(f => !checkedKeys.find(k => k === f.id))});
      showNotification(
        "success",
        "Succès",
        `${checkedKeys.length} fichiers supprimés avec succès`
      );
    } catch (err) {
      message.destroy();
      showNotification("error", "Erreur", err);
    }
  };

  blobToDataURL = blob => {
    return new Promise((fulfill, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = e => fulfill(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  onFilesChanged = files => {
    console.log("in files changed modal");
    const {form} = this.formRef.props;
    form.setFields({
      documents: {
        errors: null,
      },
    });
    this.setState({
      files,
    });
  };

  onFilesUploaded = files => {
    this.setState({
      files: [...this.state.files, ...files],
    });
  };

  render() {
    const {defaultExpandedKeys = [], filtersEnabled = false} = this.props;
    const {showMenu, files} = this.state;
    let display = "none";
    if (showMenu) {
      display = "block";
    }

    let parent = 1;
    let child = 0;

    const titlesArray = {
      commande: "Commandes",
      bpa: "Bons pour accord",
      plan: "Plans",
      arrete: "Arrêtés",
      dict: "DT-DICT",
      rapportAnalyse: "Rapports d'analyses laboratoire",
      rapportDefinitif: "Rapports définitifs",
      pvReception: "PV de réception",
      releveContradictoire: "Relevés contradictoires",
      feuilleSaisie: "Feuilles de saisies",
      offre: "Offres",
      divers: "Divers",
      template: "Modèle de document",
      kml: "Fichiers KML",
    };

    let key = 0;
    const list = files.reduce((current, next) => {
      if (!current[next.docType]) {
        if (filtersEnabled && !defaultExpandedKeys.includes(next.docType)) {
          return current;
        }
        current[next.docType] = {};
        current[next.docType].key = key;
        current[next.docType].name = next.docType;
        current[next.docType].index = `0-${parent}`;
        current[next.docType].childs = [];
        parent += 1;
        key += 1;
        child = 0;
        defaultExpandedKeys.push(current[next.docType].index);
      }
      const file = {...next};
      file.index = `0-${parent}-${child}`;
      file.icon = `${prettyFileIcons.getIcon(file.name)}.svg`;
      child += 1;
      current[next.docType].childs.push(file);
      return current;
    }, {});

    const {isImage, filePath, docType, type, name, showUploader} = this.state;
    console.log(type);

    const Preloader = () => <Spin size="large" style={{marginTop: 100}} />;
    return (
      <div>
        <ContextMenuTrigger id="contextMenu">
          <Row gutter={12}>
            <Col span={10}>
              {showUploader && (
                <ManualUploader
                  accept=".pdf,.doc,.docx,.xls,.xlsx"
                  client={this.props.client}
                  onDelete={this.onDelete}
                  affaireId={this.props.affaireId}
                  defaultType="dict"
                  visibles={["dict"]}
                  fileTypes={[
                    {
                      label: "DT-DICT",
                      value: "dict",
                    },
                  ]}
                  onFilesUploaded={this.onFilesUploaded}
                  onFilesChanged={this.props.onFilesChanged}
                  onStart={this.props.onUploadstart}
                />
              )}
              <DirectoryTree
                checkable
                multiple
                defaultExpandAll={!!defaultExpandedKeys.length}
                defaultExpandedKeys={
                  defaultExpandedKeys.length ? [defaultExpandedKeys] : []
                }
                onSelect={this.onSelect}
                onExpand={this.onExpand}
                onRightClick={this.handleEvent}
                onCheck={this.onCheck}
              >
                <Divider />
                <TreeNode title="Documents" key="0-0">
                  {Object.keys(list).map(type => (
                    <TreeNode title={titlesArray[type]} key={list[type].index}>
                      {list[type].childs.map(file => (
                        <TreeNode
                          checkable
                          {...file}
                          title={file.name}
                          icon={
                            <img
                              style={{height: 18, width: 18}}
                              src={Images[file.icon]}
                              alt=""
                            />
                          }
                          key={file.index}
                          isLeaf
                        />
                      ))}
                    </TreeNode>
                  ))}
                </TreeNode>
              </DirectoryTree>
            </Col>
            <Col span={14} align="middle" justify="middle">
              {isImage ? (
                <div>
                  <ImageLoader src={filePath} height={500}>
                    <ImageZoom
                      image={{
                        src: filePath,
                        alt: "",
                        className: "",
                        style: {maxHeight: "calc(100vh - 100px)"},
                      }}
                      zoomImage={{
                        src: filePath,
                        alt: "",
                      }}
                    />
                    <div>Impossible de charger cette image !</div>
                    <Preloader />
                  </ImageLoader>
                </div>
              ) : (
                <div style={{height: 200, width: 200}}>
                  {type === "application/pdf" && (
                    <PdfDrawer
                      onPdfClose={this.onPdfClose}
                      filePath={filePath}
                      fileName={name}
                    />
                  )}
                </div>
              )}
            </Col>
          </Row>
        </ContextMenuTrigger>
        <ContextMenu
          id="contextMenu"
          hideOnLeave
          style={{
            display: "block",
            background: "white",
            padding: 5,
            border: "1px solid grey",
            zIndex: 100,
            borderRadius: 4,
          }}
        >
          <Menu>
            <Menu.Item key="1" onClick={this.onDownloadfile}>
              <FontAwesome name="download" />
              Télécharger
            </Menu.Item>
            {this.props.userRole && this.props.userRole === "Admin" && (
              <Menu.Item key="2" onClick={this.onRemoveFile}>
                <FontAwesome name="trash-alt" />
                Supprimer la selection
              </Menu.Item>
            )}
          </Menu>
        </ContextMenu>
      </div>
    );
  }
}

export default FileTree;
