import React from "react";

// GraphQL
import {Link} from "react-router-dom";
import {Query} from "react-apollo";
import moment from "moment";
import {Badge, Button, Popconfirm} from "antd";
import {orderBy} from "lodash";
import FontAwesome from "react-fontawesome";
import Pie from "components/stateless/Pie";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {Row, Col} from "antd";

/* App */
import {Loading, ScrollTable, showNotification} from "src/components/stateless"; // custom components
import {defaultSort} from "src/helpers";

import GET_TRANSACTIONS_LIST from "src/graphql/queries/getAllTransactions.gql";

class TransactionsList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      LinetoExpand: null,
    };
  }

  render() {
    return (
      <Query query={GET_TRANSACTIONS_LIST} fetchPolicy="network-only">
        {({loading, error, data}) => {
          if (loading) return <Loading />;
          if (error) return showNotification("error", "", error.message);

          const {Transactions} = data;
          const {user} = this.props;

          const defaultSorted = [
            {
              dataField: "date", // if dataField is not match to any column you defined, it will be ignored.
              order: "asc", // desc or asc
              sortValue: (cell, row) => moment(cell, "DD/MM/YYYY HH:mm:ss").unix(),
              sortFunc: (a, b, order, dataField) => {
                return a - b; // desc
              },
            },
          ];

          const levels = {
            0: "volcano",
            1: "blue",
            2: "purple",
            3: "geekblue",
            4: "magenta",
            5: "green",
          };

          const columns = [
            {
              dataField: "view",
              text: "",
              csvExport: false,
              headerStyle: () => ({
                width: 28,
              }),
            },
            {
              dataField: "delete",
              text: "",
              csvExport: false,
              headerStyle: () => ({
                width: 28,
              }),
            },
            {
              dataField: "nettyId",
              text: "Id du bien netty",
            },
            {
              dataField: "user",
              text: "Crée par",
            },
            {
              dataField: "date",
              text: "Date",
              sort: false,
              //...defaultSort,
            },
            {
              dataField: "commissionAmountHt",
              text: "Commission (HT)",
              formatter: e => `${parseFloat(e).toFixed(2)} €`,
            },
            {
              dataField: "mandat",
              text: "Mandat",
            },
            {
              dataField: "prescriptors",
              text: "Prescripteurs",
            },
            {
              dataField: "recommandations",
              text: "Recommandations",
            },
          ];

          let transactionsList = Transactions.list;

          if (user.role.name !== "Admin") {
            // if not admin filter user own transactions or from his childrens
            console.log("NOT ADMIN", Transactions.list);
            transactionsList = Transactions.list.filter(t => {
              if (
                t.user.id === user.id ||
                t.beneficiaries.find(u => u.user.id === user.id)
              ) {
                return t;
              }
            });
          }
          const list = transactionsList.reduce(
            (prev, curr) => [
              ...prev,
              {
                ...curr,
                user: `${curr.user.firstname} ${curr.user.lastname}`,
                userId: curr.user.id,
                date: moment(curr.createdAt).format("DD/MM/YYYY HH:mm:ss"),
                view: (
                  <Button type="primary" onClick={e => this.props.onEditClicked(curr.id)}>
                    <i className="fa fa-eye" />
                  </Button>
                ),
                commissionAmount: curr.commissionAmount + " €",
                prescriptors: curr.hasPrescriptor ? (
                  <div>
                    <span>
                      <FontAwesome name="sign-in-alt" style={{color: "#1890ff"}} />
                      Entrant:
                      {curr.inputPrescriptor
                        ? ` ${curr.inputPrescriptor.firstname} ${curr.inputPrescriptor.lastname}`
                        : " Aucun"}
                    </span>
                    <br />
                    <span>
                      <FontAwesome
                        name="sign-out-alt"
                        style={{color: "rgb(88, 196, 26)"}}
                      />
                      Sortant:
                      {curr.outputPrescriptor
                        ? ` ${curr.outputPrescriptor.firstname} ${curr.outputPrescriptor.lastname}`
                        : " Aucun"}
                    </span>
                  </div>
                ) : (
                  "Aucun"
                ),
                recommandations: curr.hasRecommandation ? (
                  <div>
                    <span>
                      <FontAwesome name="sign-in-alt" style={{color: "#1890ff"}} />
                      Entrante:
                      {curr.inputRecommandator
                        ? ` ${curr.inputRecommandator.firstname} ${curr.inputRecommandator.lastname}`
                        : " Aucune"}
                    </span>
                    <br />
                    <span>
                      <FontAwesome
                        name="sign-out-alt"
                        style={{color: "rgb(88, 196, 26)"}}
                      />
                      Sortante:
                      {curr.outputRecommandator
                        ? ` ${curr.outputRecommandator.firstname} ${curr.outputRecommandator.lastname}`
                        : " Aucune"}
                    </span>
                  </div>
                ) : (
                  "Aucune"
                ),
                mandat: (
                  <div>
                    <span>
                      <FontAwesome name="sign-in-alt" style={{color: "#1890ff"}} />
                      Entrant:
                      {curr.representativeInput
                        ? ` ${curr.representativeInput.firstname} ${curr.representativeInput.lastname}`
                        : " Aucun"}
                    </span>
                    <br />
                    <span>
                      <FontAwesome
                        name="sign-out-alt"
                        style={{color: "rgb(159, 196, 26)"}}
                      />
                      Sortant:
                      {curr.user
                        ? ` ${curr.user.firstname} ${curr.user.lastname}`
                        : " Aucun"}
                    </span>
                  </div>
                ),
                delete: (
                  <Popconfirm
                    title="Title"
                    icon={<QuestionCircleOutlined style={{color: "red"}} />}
                    onConfirm={() => this.props.onDeleteClicked(curr.id)}
                    onVisibleChange={() => console.log("delete canceled")}
                    title={"Voulez-vous vraiment supprimer cette transaction ?"}
                    okText="Oui"
                    cancelText="Annuler"
                  >
                    <Button type="primary" danger>
                      <i className="fa fa-trash" />
                    </Button>
                  </Popconfirm>
                ),
              },
            ],
            []
          );

          const expandRow = {
            renderer: row => {
              let oAmount = 0;
              let rAmount = 0;
              const data = orderBy(row.beneficiaries, r => r.amount, "desc").reduce(
                (c, n) => {
                  if (n.level > 0) {
                    if (c.repartition[n.user.id]) {
                      c.repartition[n.user.id].value += n.user.needTva
                        ? n.amount - n.vatAmount
                        : n.amount;
                    } else {
                      c.repartition[n.user.id] = {
                        value: n.user.needTva ? n.amount - n.vatAmount : n.amount,
                        type: `${n.user.firstname} ${n.user.lastname}`,
                      };
                    }
                    rAmount += n.user.needTva ? n.amount - n.vatAmount : n.amount;
                  } else {
                    if (c.others[n.user.id]) {
                      c.others[n.user.id].value += n.user.needTva
                        ? n.amount - n.vatAmount
                        : n.amount;
                    } else {
                      c.others[n.user.id] = {
                        value: n.user.needTva ? n.amount - n.vatAmount : n.amount,
                        type: `${n.user.firstname} ${n.user.lastname}`,
                      };
                    }
                    oAmount += n.user.needTva ? n.amount - n.vatAmount : n.amount;
                  }
                  return c;
                },
                {repartition: {}, others: {}}
              );

              const title = "Commission (HT):";
              const content = `${row.amount} €`;

              const olist = Object.keys(data.others).map(i => data.others[i]);
              const rlist = Object.keys(data.repartition).map(i => data.repartition[i]);
              console.log(olist, rlist);
              return (
                <>
                  <div
                    style={{
                      width: "50%",
                      float: "left",
                      minHeight: 400,
                    }}
                  >
                    <Pie data={olist} title={"Honoraires"} content={`${oAmount} €`} />
                  </div>
                  <div
                    style={{
                      width: "50%",
                      float: "right",
                      textAlign: "center",
                      minHeight: 400,
                      display: rlist.length ? "block" : "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {rlist.length ? (
                      <Pie
                        data={rlist}
                        title={"Répartitions"}
                        content={`${rAmount} €`}
                        style={{width: "50%"}}
                      />
                    ) : (
                      <h5>Aucune répartition sur cette transaction</h5>
                    )}
                  </div>
                </>
              );
            },
            className: (isExpanded, row, rowIndex) => {
              if (isExpanded) return "container";
              return "hidden";
            },
            onlyOneExpanding: true,
            expandByColumnOnly: true,
            nonExpandable: [0, 1, 2, 3, 4],
            showExpandColumn: true,
            expandColumnPosition: "right",
            expandHeaderColumnRenderer: ({isAnyExpands}) => (
              <b style={{fontWeight: 700}}>Bénéficiaires</b>
            ),
            expandColumnRenderer: ({expanded, rowKey, expandable, r, e}) => {
              return (
                <span>
                  <FontAwesome
                    name="chart-pie"
                    style={{
                      color: expanded ? "#F1AF1D" : "#0BAC4C",
                      fontSize: 32,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </span>
              );
            },
          };

          const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(e.target, row);
              //this.setState({LinetoExpand: rowIndex});
            },
          };

          return (
            <div className="card">
              <ScrollTable
                search
                list={list.reverse()}
                expandRow={expandRow}
                rowEvents={rowEvents}
                columns={columns}
                {...this.props}
                expanded={[this.state.LinetoExpand]}
                defaultSorted={defaultSorted}
              />
            </div>
          );
        }}
      </Query>
    );
  }
}

export default TransactionsList;
