import React from "react";
import {Drawer, Alert} from "antd";
import PDFViewer from "pdf-viewer-reactjs";
import FontAwesome from "react-fontawesome";
import {Loading} from "src/components/stateless";

class PdfDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      filePath: this.props.filePath || null,
      fileName: this.props.fileName || "Document sans titre...",
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props.onPdfClose();
  };

  render() {
    const {filePath, fileName} = this.state;
    console.log(filePath);
    return (
      <div>
        <Drawer
          width={"calc(100vw - 200px)"}
          closable
          title={fileName}
          placement={this.state.placement}
          onClose={this.onClose}
          visible={this.state.visible}
          destroyOnClose
        >
          <PDFViewer
            loader={<Loading />}
            alert={() => (
              <Alert
                message={
                  <div>
                    <FontAwesome name="exclamation-circle" />
                    Erreur de chargement de votre pdf (indisponible ou fichier corrompu)
                  </div>
                }
                type="error"
              />
            )}
            scale={1.5}
            document={{
              url: filePath,
            }}
          />
        </Drawer>
      </div>
    );
  }
}

export default PdfDrawer;
