import React from "react";
import {useEffect, useState} from "react";
import {Table, Switch, Space} from "antd";
import FontAwesome from "react-fontawesome";

function AntdTable(props) {
  const [data, setData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  function onExpandedRowsChange(expanded, record, i) {
    console.log(expanded, record, i);
  }

  // rowSelection objects indicates the need for row selection
  function onTableRowExpand(expanded, record, i) {
    console.log(expanded, record, i);
    var keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRowKeys(keys);
  }

  function list_to_tree() {
    const tree = {};
    let withoutRoot = false;
    const list = props.data;

    list.map(v => {
      if (!tree[v.nettyId] && v.from.id === v.userId) {
        // en admin trouve le vendeur pirncipal et cree les enfants dessus
        tree[v.nettyId] = {...v, children: []};
      }
    });

    list.map(v => {
      if (v.from.id !== v.userId) {
        if (!tree[v.nettyId] || withoutRoot) {
          withoutRoot = true;
          // le vendeur de base n est pas present, filtré, ajoute que les transactions pour user actuel
          if (tree[v.nettyId]) {
            tree[v.nettyId].children.push(v);
          } else {
            tree[v.nettyId] = {...v, children: []};
          }
          return;
        }
        tree[v.nettyId].children.push(v);
        tree[v.nettyId].children = tree[v.nettyId].children.sort(function(a, b) {
          return parseInt(b["profit"]) - parseInt(a["profit"]);
        });
      }
    });

    setData(Object.values(tree));
  }

  useEffect(() => {
    list_to_tree();
  }, []);

  const CustomExpandIcon = props => {
    console.log(props);
    if (!props.record.children || !props.record.children.length) return null; //nothing to expand
    if (props.expanded && props.record.children) {
      return (
        <a onClick={e => onTableRowExpand(false, props.record)}>
          <FontAwesome
            name="minus-square"
            style={{
              fontSize: 16,
              position: "relative",
              top: 1,
              color: "orange",
              background: "white",
            }}
          />
        </a>
      );
    } else if (!props.expanded && props.record.children) {
      return (
        <a onClick={e => onTableRowExpand(true, props.record)}>
          <FontAwesome
            name="plus-square"
            style={{
              fontSize: 16,
              position: "relative",
              top: 1,
              color: "#007bff",
              background: "white",
            }}
          />
        </a>
      );
    }
  };

  console.log(expandedRowKeys);

  return (
    <>
      <Table
        columns={props.columns}
        //rowSelection={{...rowSelection, checkStrictly: true}}
        dataSource={data}
        expandable={
          props.expandable
            ? {
                expandRowByClick: true,
                expandedRowKeys,
                expandIcon: p => <CustomExpandIcon {...p} />,
                onExpand: (expanded, row) => onTableRowExpand(expanded, row),
              }
            : false
        }
      />
    </>
  );
}

export default AntdTable;
