import React from "react";
import download from "downloadjs";
import Axios from "axios";

export const readKmz = inStream => {};

export const defaultSort = {
  sort: true,
  sortCaret: (order, column) => {
    if (!order) {
      return (
        <div style={{float: "right"}}>
          <i className="fa fa-caret-up" style={{display: "block", color: "#d3d5d8"}} />
          <i className="fa fa-caret-down" style={{display: "block", color: "#d3d5d8"}} />
        </div>
      );
    }
    if (order === "asc") {
      return (
        <div style={{float: "right"}}>
          <i className="fa fa-caret-up" style={{display: "block"}} />
          <i className="fa fa-caret-down" style={{display: "block", color: "#d3d5d8"}} />
        </div>
      );
    }
    if (order === "desc") {
      return (
        <div style={{float: "right"}}>
          <i className="fa fa-caret-up" style={{display: "block", color: "#d3d5d8"}} />
          <i className="fa fa-caret-down" style={{display: "block"}} />
        </div>
      );
    }
    return null;
  },
};

export const compareByAlphAndBool = (a, b) => {
  // for text
  if (a > b) return -1;
  if (a < b) return 1;
  // for booleans
  if (a && !b) return 1;
  if (!a && b) return -1;

  return 0;
};

export const uuid = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;
    const v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const downloadFile = async (url, name, mime, size) => {
  const res = await Axios({
    method: "GET",
    url,
    responseType: "blob",
    onDownloadProgress: progressEvent => {
      console.log(progressEvent);
      const percentCompleted = Math.floor((progressEvent.loaded * 100) / size);
      console.log(percentCompleted);
    },
  });
  console.log(res);
  const blob = res.data;

  // from downloadjs it will download your file
  download(blob, name, mime);
  // return blob;
};

class MercatorProjection {
  constructor(maps) {
    this.maps = maps; // api
    this.MERCATOR_RANGEE = 256;
    this.pixelOrigin = new maps.Point(this.MERCATOR_RANGEE / 2, this.MERCATOR_RANGEE / 2);
    this.pixelsPerLonDegree = this.MERCATOR_RANGEE / 360;
    this.pixelsPerLonRadian = this.MERCATOR_RANGEE / (2 * Math.PI);
  }

  fromLatLngToPoint = (latLng, optPoint) => {
    const point = optPoint || new this.maps.Point(0, 0);
    const origin = this.pixelOrigin;
    point.x = (origin.x + latLng.lng()) * this.pixelsPerLonDegree;
    const siny = this.bound(
      Math.sin(this.degreesToRadians(latLng.lat())),
      -0.9999,
      0.9999
    );
    point.y =
      origin.y + 0.5 * Math.log((1 + siny) / (1 - siny)) * -this.pixelsPerLonRadian;
    return point;
  };

  fromDivPixelToLatLng = (pixel, zoom) => {
    const origin = this.pixelOrigin;
    const scale = Math.pow(2, zoom);
    const lng = (pixel.x / scale - origin.x) / this.pixelsPerLonDegree;
    const latRadians = (pixel.y / scale - origin.y) / -this.pixelsPerLonRadian;
    const lat = this.radiansToDegrees(2 * Math.atan(Math.exp(latRadians)) - Math.PI / 2);
    return new this.maps.LatLng(lat, lng);
  };

  fromDivPixelToSphericalMercator = (pixel, zoom) => {
    const coord = this.fromDivPixelToLatLng(pixel, zoom);

    const r = 6378137.0;
    const x = r * this.degreesToRadians(coord.lng());
    const latRad = this.degreesToRadians(coord.lat());
    const y = (r / 2) * Math.log((1 + Math.sin(latRad)) / (1 - Math.sin(latRad)));

    return new this.maps.Point(x, y);
  };

  degreesToRadians = deg => deg * (Math.PI / 180);

  radiansToDegrees = rad => rad / (Math.PI / 180);

  bound = (value, optMin, optMax) => {
    if (optMin != null) value = Math.max(value, optMin);
    if (optMax != null) value = Math.min(value, optMax);
    return value;
  };
}

const calculateBBOX = (coordinates, zoomlvl, maps) => {
  let bbox = "479406.21274558,6807170.2079419,484298.18255515,6812062.1777515";

  const lULP = new maps.Point(coordinates.x * 256, (coordinates.y + 1) * 256);
  const lLRP = new maps.Point((coordinates.x + 1) * 256, coordinates.y * 256);

  const projectionMap = new MercatorProjection(maps);

  const lULg = projectionMap.fromDivPixelToSphericalMercator(lULP, zoomlvl, maps);
  const lLRg = projectionMap.fromDivPixelToSphericalMercator(lLRP, zoomlvl, maps);

  const lulLatitude = lULg.y;
  const lulLongitude = lULg.x;
  const llrLatitude = lLRg.y;
  let llrLongitude = lLRg.x;
  if (llrLongitude < lulLongitude) {
    llrLongitude = Math.abs(llrLongitude);
  }
  bbox = `${lulLongitude},${lulLatitude},${llrLongitude},${llrLatitude}`;
  return bbox;
};

class AddressLayer {
  constructor(size, maps) {
    this.maps = maps;
    this.name = "address";
    this.tileSize = {
      width: 256,
      height: 256,
    };
  }

  getTile = (coord, zoom, ownerDocument) => {
    const div = ownerDocument.createElement("div");
    const bbox = calculateBBOX(coord, zoom, this.maps);
    //TN.RoadTransportNetwork
    const url = `https://wxs.ign.fr/vb3cud7tmgo1aaji9bp9tqbs/inspire/v/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&LAYERS=AD.Address&STYLES=&CRS=EPSG:3857&BBOX=${bbox}&WIDTH=256&HEIGHT=256&FORMAT=image/png&TRANSPARENT=TRUE&EXCEPTIONS=XML`;
    div.innerHTML = `<img src="${url}" />`;
    div.style.width = `${this.tileSize.width + 1}px`;
    div.style.height = `${this.tileSize.height + 1}px`;
    return div;
  };
}

class TransportLayer {
  constructor(size, maps) {
    this.maps = maps;
    this.name = "transport";
    this.tileSize = {
      width: 256,
      height: 256,
    };
  }

  getTile = (coord, zoom, ownerDocument) => {
    const div = ownerDocument.createElement("div");
    const bbox = calculateBBOX(coord, zoom, this.maps);
    //TN.RoadTransportNetwork
    const url = `https://wxs.ign.fr/vb3cud7tmgo1aaji9bp9tqbs/inspire/v/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&LAYERS=TN.RoadTransportNetwork&STYLES=&CRS=EPSG:3857&BBOX=${bbox}&WIDTH=256&HEIGHT=256&FORMAT=image/png&TRANSPARENT=TRUE&EXCEPTIONS=XML`;
    div.innerHTML = `<img src="${url}" />`;
    div.style.width = `${this.tileSize.width + 1}px`;
    div.style.height = `${this.tileSize.height + 1}px`;
    return div;
  };
}

class CadastralLayer {
  constructor(size, maps) {
    this.maps = maps;
    this.name = "cadastre";
    this.tileSize = {
      width: 256,
      height: 256,
    };
  }

  getTile = (coord, zoom, ownerDocument) => {
    const div = ownerDocument.createElement("div");
    const bbox = calculateBBOX(coord, zoom, this.maps);
    const url = `https://wxs.ign.fr/vb3cud7tmgo1aaji9bp9tqbs/inspire/r/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&LAYERS=CP.CadastralParcel&STYLES=&CRS=EPSG:3857&BBOX=${bbox}&WIDTH=256&HEIGHT=256&FORMAT=image/png&TRANSPARENT=TRUE&EXCEPTIONS=XML`;
    div.innerHTML = `<img src="${url}" />`;
    div.style.width = `${this.tileSize.width}px`;
    div.style.height = `${this.tileSize.height}px`;
    return div;
  };
}

//////////////////////////////
// files cleaner

const filterFiles = files => {
  let list = Object.keys(files)
    .filter(k => !k.match(/(uid|status|percent)/))
    .reduce((c, n) => {
      c[n] = files[n];
      return c;
    }, {});
  return list;
};

export {
  AddressLayer,
  TransportLayer,
  CadastralLayer,
  MercatorProjection,
  download,
  filterFiles,
};
