import React from "react";
import {Upload, Button, message, Divider, Row, Col, Select} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import axios from "axios";
import {loadProgressBar} from "axios-progress-bar";
import FontAwesome from "react-fontawesome";
import config from "src/config";
import {addFile} from "src/actions/files";
const {Option} = Select;

class ManualUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      uploading: false,
      progress: 0,
      affaireId: props.affaireId || null,
    };
  }

  componentDidMount = () => {
    loadProgressBar();
  };

  handleUpload = () => {
    const {fileList} = this.state;
    const formData = new FormData();

    fileList.forEach(file => {
      formData.append("files[]", file);
    });

    this.setState({
      uploading: true,
    });

    // You can use any AJAX library you like
    axios({
      url: "https://api.lart2vendreimmobilier.com/upload",
      method: "post",
      data: formData,
      onUploadProgress: p => {
        console.log(p);
        this.setState({
          progress: Math.round((p.loaded * 100) / p.total),
        });
      },
    })
      .then(async result => {
        const newFiles = [];
        for (const file of result.data.files) {
          const newFile = {
            type: file.type,
            name: file.name,
            path: file.path,
            size: file.size,
            docType: this.state.fileList.find(f => f.name === file.name).docType,
            affaireId: this.state.affaireId,
          };
          const data = await addFile(newFile, this.props.client);
          newFile.id = data[0].id;
          newFiles.push(newFile);
        }
        // return to filetree
        if (this.props.onFilesUploaded) {
          this.props.onFilesUploaded(newFiles);
        }
        this.setState({
          fileList: [],
          uploading: false,
          progress: 0,
        });
        message.success("Fichiers envoyés avec succès");
      })
      .catch(err => {
        console.log(err);
        this.setState({
          uploading: false,
          progress: 0,
        });
        message.error("Erreur d'envoi des fichiers");
      });
  };

  handleChange = (val, file) => {
    console.log(`selected ${val} ${file}`);
    this.setState({
      fileList: this.state.fileList.map(f =>
        f.uid === file.uid ? Object.assign(f, {docType: val}) : f
      ),
    });
  };

  render() {
    const {uploading, fileList, affaireId} = this.state;
    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        file.docType = null;
        file.affaireId = affaireId;
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    return (
      <div>
        <h6>Ajouter des fichiers</h6>
        <Divider />
        <Upload {...props} multiple showUploadList={false}>
          <Button>
            <UploadOutlined /> Sélectionnez vos fichiers
          </Button>
        </Upload>
        {fileList.length !== 0 && (
          <div>
            {fileList.map(f => {
              return (
                <Row
                  style={{
                    padding: 10,
                    display: "inline-flex",
                    alignItems: "baseline",
                    width: "100%",
                  }}
                >
                  <Col span={12}>
                    <span>{f.name}</span>
                  </Col>
                  <Col span={10}>
                    <Select
                      style={{
                        border: !f.docType ? "1px solid red" : "none",
                        width: "100%",
                      }}
                      placeholder="Sélectionnez un type de fichier"
                      onChange={e => this.handleChange(e, f)}
                    >
                      {config.fileTypes.map(type => (
                        <Option value={type.value}>{type.label}</Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={2} className="removeUploadFile">
                    <span style={{width: 12}} onClick={e => props.onRemove(e, f)}>
                      <FontAwesome name="trash" />
                    </span>
                  </Col>
                </Row>
              );
            })}
          </div>
        )}
        <Button
          type="primary"
          onClick={this.handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{
            marginTop: 16,
          }}
        >
          {uploading ? "Enregistrement..." : "Enregistrer"}
        </Button>
      </div>
    );
  }
}

export default ManualUploader;
