import React, {Component, useState, useMutation} from "react";
import {Redirect} from "react-router";

import {LockOutlined, MailOutlined} from "@ant-design/icons";

import {Form} from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";

import {Input, Button, Card, Alert} from "antd";

import {Loading} from "components/stateless";
import ME_QUERY from "src/graphql/queries/me.gql";
import {FormComponentProps} from "@ant-design/compatible/lib/form";

import {useLazyQuery} from "@apollo/react-hooks";

import Logo from "src/assets/img/logo.png";
import {history} from "src/router/history";

import gql from "graphql-tag";

const FormItem = Form.Item;
const {Meta} = Card;

const LOGIN = gql`
  query login($email: String!, $password: String!) {
    Users {
      login(email: $email, password: $password) {
        token
      }
    }
  }
`;

const Login = props => {
  const {form} = props;
  const {getFieldDecorator} = form;
  const [values, setValues] = useState({});

  // Lazy query for login user method
  const [loginUser, {loading, data, error}] = useLazyQuery(LOGIN, {
    variables: values,
    errorPolicy: "all",
    onError: errors => errors,
  });

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        setValues(values);
        loginUser();
      }
    });
  };

  if (loading) {
    return <Loading />;
  }

  if (!error && data) {
    window.localStorage.setItem("adv_stats", data.Users.login.token);
    props.logUser();
  }

  return (
    <div className="center-screen">
      <Card
        style={{width: 400}}
        cover={<img id="login-logo" alt="example" className="login-logo" src={Logo} />}
        actions={[]}
      >
        <Meta
          align="middle"
          title="A2V Stats"
          description={
            error ? (
              <Alert
                message={error.message.replace("GraphQL error:", "")}
                description=""
                type="error"
                showIcon
              />
            ) : (
              "Merci de vous identifier"
            )
          }
        />
        <Form onSubmit={handleSubmit} className="login-form" style={{marginTop: "10%"}}>
          <FormItem>
            {getFieldDecorator("email", {
              rules: [{required: true, message: "Merci d'indiquer votre adresse email"}],
            })(
              <Input
                prefix={<MailOutlined style={{color: "rgba(0,0,0,.25)"}} />}
                placeholder="Email"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("password", {
              rules: [{required: true, message: "Merci d'indiquer votre mot de passe!"}],
            })(
              <Input
                prefix={<LockOutlined style={{color: "rgba(0,0,0,.25)"}} />}
                type="password"
                placeholder="Mot de passe"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("remember", {
              valuePropName: "checked",
              initialValue: true,
            })}

            <Button type="primary" htmlType="submit" className="login-form-button">
              Envoyer
            </Button>
          </FormItem>
        </Form>
      </Card>
    </div>
  );
};

const WrappedLoginForm = Form.create()(Login);
export default WrappedLoginForm;
