/*
 * action types
 */
import getAllAffaires from "src/graphql/queries/all_affaires.gql";
import getAffaire from "src/graphql/queries/getAffaire.gql";
import getAffaireWithManualJoint from "src/graphql/queries/getAffaireWithManualJoint.gql";
import UPDATE_AFFAIRE_MUTATION from "src/graphql/mutations/updateAffaire.gql";

export const GET_AFFAIRES_LIST = "GET_AFFAIRES_LIST";
export const ADD_CASE = "ADD_CASE";
export const GET_CURRENT_AFFAIRE = "GET_CURRENT_AFFAIRE";
export const UPDATE_AFFAIRE = "UPDATE_AFFAIRE";

/*
 * action creators
 */

export const updateAffairesList = async client => {
  const data = await client.query({
    query: getAllAffaires,
    fetchPolicy: "network-only",
  });

  return data.data.Affaires.list;
};

export function addAffaire(body) {
  return {type: ADD_CASE, body};
}

export const getCurrentAffaire = async (id, client) => {
  console.log(id, client);
  const data = await client.query({
    query: getAffaire,
    fetchPolicy: "network-only",
    variables: {id},
  });

  return data.data.Affaires.get;
};

export const getCurrentAffaireWithManualJoint = async (id, client) => {
  console.log(id, client);
  const data = await client.query({
    query: getAffaireWithManualJoint,
    fetchPolicy: "network-only",
    variables: {id},
  });

  return data.data.Affaires.getWithManualJoints;
};

export async function updateAffaire(input, client) {
  const data = await client.mutate({
    mutation: UPDATE_AFFAIRE_MUTATION,
    variables: {input},
  });

  return data.data.Affaire.update;
}
