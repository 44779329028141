/*
 * action types
 */

import getAllTransactions from "src/graphql/queries/getAllTransactions.gql";
import getTransaction from "src/graphql/queries/getTransaction.gql";
import getAllEarnings from "src/graphql/queries/getAllEarnings.gql";
import UPDATE_TRANSACTION_MUTATION from "src/graphql/mutations/updateTransaction.gql";
import ADD_TRANSACTION_MUTATION from "src/graphql/mutations/addTransaction.gql";
import DELETE_TRANSACTION_MUTATION from "src/graphql/mutations/deleteTransaction.gql";

/*
 * action creators
 */

export async function updateTransactionsList(client) {
  const data = await client.query({
    query: getAllTransactions,
    fetchPolicy: "network-only",
  });

  return data.data.Transactions.list;
}

export async function addTransaction(input, client) {
  const data = await client.mutate({
    mutation: ADD_TRANSACTION_MUTATION,
    variables: {input},
  });

  return data.data.Transactions.add;
}

export async function updateTransaction(input, client) {
  const data = await client.mutate({
    mutation: UPDATE_TRANSACTION_MUTATION,
    variables: {input},
  });

  return data.data.Transactions.update;
}

export async function getCurrentTransaction(id, client) {
  const res = await client.query({
    query: getTransaction,
    fetchPolicy: "network-only",
    variables: {id},
  });
  return res.data.Transactions.get;
}

export async function updateEarningsList(client) {
  const data = await client.query({
    query: getAllEarnings,
    fetchPolicy: "network-only",
  });

  return data.data.getAllEarnings.list;
}

export async function deleteTransaction(id, client) {
  const data = await client.mutate({
    mutation: DELETE_TRANSACTION_MUTATION,
    variables: {id},
  });

  return data.data.Transactions.delete;
}
