import GET_PRESCRIPTORS from "src/graphql/queries/getPrescriptors.gql";
import SEARCH_REPRESENTATIVE from "src/graphql/queries/searchRepresentative.gql";
import GET_USER from "src/graphql/queries/getUser.gql";
import GET_CHILDRENS_OF_ID from "src/graphql/queries/getChildrensOfId.gql";
import GET_USERS_LIST from "src/graphql/queries/listUsers.gql";

export async function getPrescriptors(client) {
  const data = await client.query({
    query: GET_PRESCRIPTORS,
    fetchPolicy: "network-only",
  });

  return data.data.Users.getPrescriptors;
}

export async function searchRepresentative(value, client) {
  const data = await client.query({
    query: SEARCH_REPRESENTATIVE,
    variables: {value},
    fetchPolicy: "network-only",
  });

  return data.data.Users.searchRepresentative;
}

export async function getUser(id, client) {
  const data = await client.query({
    query: GET_USER,
    variables: {id},
    fetchPolicy: "network-only",
  });

  return data.data.Users.get;
}

export async function getChildrensOfId(root, client) {
  const data = await client.query({
    query: GET_CHILDRENS_OF_ID,
    variables: {root},
    fetchPolicy: "network-only",
  });

  return data.data.Users.getChildrensOfId;
}

export async function getUsersList(client) {
  const data = await client.query({
    query: GET_USERS_LIST,
    fetchPolicy: "network-only",
  });

  return data.data.Users.list;
}
