import React from "react";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {Form} from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {Input, Button, Select, Divider} from "antd";
import FontAwesome from "react-fontawesome";
import moment from "moment";
import {orderBy} from "lodash";

let id = 0;
let names = [];
let date = null;
let mode = "add";
//let visible = false;

class DynamicFieldSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldUsers: props.fieldUsers,
      availableFieldUsers: props.fieldUsers,
    };
  }

  remove = k => {
    const {form} = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    const names = form.getFieldValue("names");
    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });

    this.setState({
      availableFieldUsers: [
        ...this.state.availableFieldUsers,
        ...names[k].map(id =>
          this.state.fieldUsers.find(u => `${u.firstname} ${u.lastname}` === id)
        ),
      ],
    });
  };

  init = () => {
    const {form} = this.props;
    if (this.props.teams) {
      const list = orderBy(this.props.teams, obj => parseInt(obj.name.split(" ")[1]));
      const keys = Array.from(Array(Object.keys(list).length).keys());
      names = list.map(t => t.members.map(c => c.id));
      id = list.length;
      mode = list.length ? "update" : "add";
      this.setState({
        availableFieldUsers: this.props.fieldUsers.filter(
          u => !names.flat().includes(u.id)
        ),
      });
      names.map((arr, i) => {
        const newNames = arr.map(n => {
          let user = this.state.fieldUsers.find(u => u.id === n);
          if (user && user.id) {
            return `${user.firstname} ${user.lastname}`;
          } else {
            return n;
          }
        });
        names[i] = newNames;
      });
      this.props.form.setFieldsValue({
        keys,
        names,
      });
    }
    date = this.props.date;
  };

  add = () => {
    const {form} = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  handleSubmit = e => {
    if (e) e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(values);
      if (!err) {
        const {keys, names} = values;
        const merged = keys.map((key, i) => {
          const obj = {
            date,
            name: `Équipe ${i + 1}`,
            members: names[key].map(
              n =>
                this.state.fieldUsers.find(u => `${u.firstname} ${u.lastname}` === n).id
            ),
          };
          if (this.props.teams && this.props.teams[key] && this.props.teams[key].id) {
            obj.id = this.props.teams[key].id;
          }
          return obj;
        });
        this.props.onSubmit(merged, mode);
        // this.props.form.resetFields();
        // visible = false;
      }
    });
  };

  onFielduserSelect = (id, index) => {
    const names = this.props.form.getFieldValue("names");
    const newNames = names[index].map(n => {
      let user = this.state.fieldUsers.find(u => u.id === n);
      if (user && user.id) {
        return `${user.firstname} ${user.lastname}`;
      } else {
        return n;
      }
    });
    names[index] = newNames;
    this.setState({
      availableFieldUsers: [
        ...this.state.availableFieldUsers.filter(u => !u.disabled && u.id !== id),
      ],
    });
    this.props.form.setFieldsValue({
      names,
    });
  };

  onFielduserDeselect = (id, index) => {
    console.log(id, index, this.state.fieldUsers);
    this.setState({
      availableFieldUsers: [
        ...this.state.availableFieldUsers,
        this.state.fieldUsers.find(u => `${u.firstname} ${u.lastname}` === id),
      ],
    });
  };

  render() {
    const {getFieldDecorator, getFieldValue} = this.props.form;
    const {availableFieldUsers} = this.state;
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 8,
        },
        sm: {
          span: 4,
        },
      },
      wrapperCol: {
        xs: {
          span: 16,
        },
        sm: {
          span: 18,
        },
        lg: {
          span: 20,
        },
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: {
          span: 12,
          offset: 0,
        },
        sm: {
          span: 12,
          offset: 0,
        },
        lg: {
          span: 20,
        },
      },
    };
    getFieldDecorator("keys", {
      initialValue: [],
    });
    const keys = getFieldValue("keys");
    const formItems = keys.map((k, index) => (
      <Form.Item
        {...formItemLayout}
        label={`Équipe ${index + 1}`}
        required={false}
        key={k}
      >
        {getFieldDecorator(`names[${k}]`, {
          initialValue: names[k] || [],
          rules: [
            {
              required: true,
              message: "Merci de choisir les membres de l'équipe ou supprimez la",
              type: "array",
            },
          ],
        })(
          <Select
            mode="multiple"
            onSelect={e => this.onFielduserSelect(e, k)}
            onDeselect={e => this.onFielduserDeselect(e, k)}
            style={{
              maxWidth: 400,
            }}
            placeholder="Cliquer pour afficher la liste"
          >
            {availableFieldUsers.length &&
              availableFieldUsers.map(ac => {
                try {
                  if (!ac.disabled) {
                    return (
                      <Select.Option key={ac.id} value={ac.id}>
                        {ac.firstname} {ac.lastname}
                      </Select.Option>
                    );
                  }
                } catch (err) {
                  console.log(err);
                }
              })}
          </Select>
        )}

        <MinusCircleOutlined
          className="dynamic-delete-button"
          onClick={() => this.remove(k)}
          style={{
            marginLeft: 10,
          }}
        />
      </Form.Item>
    ));
    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          {formItems}
          <Form.Item
            {...formItemLayoutWithOutLabel}
            style={{
              marginLeft: "8.33%",
            }}
          >
            <Button type="dashed" onClick={this.add}>
              <PlusOutlined /> Ajouter une équipe
            </Button>
            <Button
              onClick={this.onGenerate}
              type="primary"
              htmlType="submit"
              style={{
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <span>
                <FontAwesome name="save" />
                {mode === "add" ? "Enregistrer" : "Mettre à jour"}
              </span>
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default DynamicFieldSet;
