import React, {Component} from "react";
import {Menu, Icon, Layout, Tabs, Row, Col, Card} from "antd";
import {Link} from "react-router-dom";
import FontAwesome from "react-fontawesome";
import gql from "graphql-tag";

import TransactionsList from "src/components/Transactions/list";
import EditTransactions from "src/components/Transactions/edit";
import {deleteTransaction} from "src/actions/transactions";

import {Loading} from "src/components/stateless";

const {TabPane} = Tabs;

class Transactions extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      current: "list",
      mode: "list",
      renderChild: true,
    };
  }

  componentDidMount = () => {
    this.props.client
      .subscribe({
        query: gql`
          subscription {
            statsUpdated {
              transactions
              userGains
              a2vGainsCount
              a2vGainsTransactionsCount
            }
          }
        `,
        variables: {},
      })
      .subscribe(async (data, client) => {
        try {
          console.log("updated stats from websocket", data.data.statsUpdated);
          this.setState({loading: true, renderChild: false});
          this.setState({
            current: "list",
            mode: "list",
            loading: false,
          });
          this.setState({
            renderChild: true,
          });
        } catch (err) {
          console.log(err);
        }
      });
  };

  componentWillReceiveProps = async nextProps => {
    if (!nextProps) {
      return;
    }
    const currentMode = this.state.mode;
    const {mode} = nextProps;

    if (currentMode !== mode) {
      this.setState({loading: true});
      this.setState({loading: false, mode});
    }
  };

  handleClick = e => {
    this.setState({loading: true});
    this.setState({
      current: e,
      mode: e,
      currentId: null,
      loading: false,
    });
  };

  onEditClicked = id => {
    this.setState({loading: true});
    this.setState({
      current: "add",
      mode: "edit",
      currentId: id,
      loading: false,
    });
  };

  onDeleteClicked = async id => {
    this.setState({loading: true, renderChild: false});
    try {
      await deleteTransaction(id, this.props.client);
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const {current, currentId, mode} = this.state;

    return (
      <div>
        <Row>
          <Col span={24}>
            <Card
              title={
                <h5>
                  <FontAwesome name="exchange-alt" style={{color: "#1890ff"}} />
                  Transactions
                </h5>
              }
            >
              <Tabs activeKey={current} onChange={this.handleClick}>
                <TabPane tab="Liste" key="list">
                  {this.state.renderChild ? (
                    <TransactionsList
                      {...this.props}
                      onEditClicked={e => this.onEditClicked(e)}
                      onDeleteClicked={e => this.onDeleteClicked(e)}
                    />
                  ) : (
                    <Loading />
                  )}
                </TabPane>

                <TabPane tab="Créer / éditer" key="add">
                  <EditTransactions {...this.props} mode={mode} currentId={currentId} />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Transactions;
