import React, {useState, useEffect} from "react";
import {Pie} from "@ant-design/charts";

const DemoPie = props => {
  var config = {
    appendPadding: 10,
    data: props.data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: v => `${parseFloat(v).toFixed(2)} €`,
      },
    },
    label: {
      type: "outer",
      content: "{name}",
    },
    statistic: {
      title: {
        formatter: v => `${props.title}`,
        offsetY: -8,
        style: {color: "#000"},
      },
      content: {
        style: {color: "#000"},
        offsetY: -4,
      },
    },
    legend: {offsetX: -90},
    interactions: [
      {type: "element-selected"},
      {type: "element-active"},
      {
        type: "pie-statistic-active",
        cfg: {
          start: [
            {trigger: "element:mouseenter", action: "pie-statistic:change"},
            {trigger: "legend-item:mouseenter", action: "pie-statistic:change"},
          ],
          end: [
            {trigger: "element:mouseleave", action: "pie-statistic:reset"},
            {trigger: "legend-item:mouseleave", action: "pie-statistic:reset"},
          ],
        },
      },
    ],
  };
  return <Pie {...config} />;
};

export default DemoPie;
