// Main component -- i.e. the 'root' React component in our app
import Main from "src/components/Main";

// Init global styles.  These will be added to the resulting CSS automatically
// without any class hashing.  Use this to include default or framework CSS.
import "./App.css";
import "./App.scss";
import "./App.less";
// ----------------------

// Browser entry point, for Webpack.  We'll grab the browser-flavoured
// versions of React mounting, routing etc to hook into the DOM

// ----------------------
// IMPORTS

/* NPM */

// Enable async/await and generators, cross-browser
import "regenerator-runtime/runtime";

// Patch global.`fetch` so that Apollo calls to GraphQL work
import "isomorphic-fetch";

// React parts
import React from "react";

// React Router, for controlling browser routes.  We'll feed in our custom
// `history` instance that's imported below, so that we have a single store of
// truth for routing
import {Router} from "react-router-dom";

// Apollo Provider. This HOC will 'wrap' our React component chain
// and handle injecting data down to any listening component
import {withApollo, ApolloProvider} from "react-apollo";

// Get the custom `history` that we'll use to feed down to our `<Router>`
import {history} from "./router/history";

// Grab the shared Apollo Client
import {browserClient} from "./apollo/client";

import {ConfigProvider} from "antd";
import frFR from "antd/es/locale/fr_FR";
// Create a new browser Apollo client
const client = browserClient();

const AppWithClient = withApollo(Main);

const App = () => (
  <ConfigProvider locale={frFR}>
    <Router history={history}>
      <ApolloProvider client={client}>
        <AppWithClient />
      </ApolloProvider>
    </Router>
  </ConfigProvider>
);

export default App;
