import React, {Component} from "react";
import {Menu, Icon, Layout, Tabs, Row, Col, Card} from "antd";
import {Link} from "react-router-dom";
import FontAwesome from "react-fontawesome";
import gql from "graphql-tag";

import InvoicesList from "src/components/Invoices/list";
// import EditInvoices from "src/components/Invoices/edit";
// import {deleteTransaction} from "src/actions/transactions";

import {Loading} from "src/components/stateless";

const {TabPane} = Tabs;

class Invoices extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      current: "list",
      mode: "list",
      renderChild: true,
    };
  }

  render() {
    const {current, currentId, mode} = this.state;

    return (
      <div>
        <Row>
          <Col span={24}>
            <Card
              title={
                <h5>
                  <FontAwesome name="file-invoice" style={{color: "#1890ff"}} />
                  Facturation
                </h5>
              }
            >
              <InvoicesList {...this.props} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Invoices;
