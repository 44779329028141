import React from "react";
import Helmet from "react-helmet";
import {Query} from "react-apollo";
import {Row, Col, Card, Alert, message, Tag} from "antd";
import {Link} from "react-router-dom";
import {Loading, ScrollTable, showNotification} from "components/stateless";
import FontAwesome from "react-fontawesome";
import {defaultSort} from "src/helpers";
import gql from "graphql-tag";

const LIST_USERS_QUERY = gql`
  {
    Users {
      list {
        id
        lastname
        firstname
        email
        phone
        mobile
        function
        disabled
        numId
        role {
          id
          name
        }
      }
    }
  }
`;

class ListUsers extends React.PureComponent {
  render() {
    return (
      <Query query={LIST_USERS_QUERY} fetchPolicy="network-only">
        {({loading, error, data}) => {
          if (loading) return <Loading />;
          if (error) return showNotification("error", "", error.message);

          const {Users} = data;

          console.log(Users);

          const defaultSorted = [
            {
              dataField: "numId", // if dataField is not match to any column you defined, it will be ignored.
              order: "asc", // desc or asc
            },
          ];

          const columns = [
            {
              dataField: "view",
              text: "",
              csvExport: false,
              headerStyle: column => ({
                width: 28,
              }),
            },
            {
              dataField: "numId",
              text: "id",
              sortValue: (cell, row) => parseInt(cell),
              ...defaultSort,
            },
            {dataField: "lastname", text: "Nom", ...defaultSort},
            {dataField: "firstname", text: "Prénom", ...defaultSort},
            {dataField: "disabled", text: "Actif", ...defaultSort},
            {dataField: "role", text: "Rôle", ...defaultSort},
            {dataField: "email", text: "Email", ...defaultSort},
            {dataField: "phone", text: "Téléphone", ...defaultSort},
            {dataField: "mobile", text: "Mobile", ...defaultSort},
          ];

          const list = Users.list
            .filter(u => parseInt(u.numId.replace("Pr-", "")) >= 1)
            .reduce(
              (prev, curr) => [
                ...prev,
                {
                  ...curr,
                  disabled: (
                    <Tag color={curr.disabled ? "error" : "success"}>
                      {curr.disabled ? "Désactivé" : "Actif"}
                    </Tag>
                  ),
                  role: curr.role.name,
                  view: (
                    <Link className="btn btn-primary" to={`/users/${curr.id}`}>
                      <i className="fa fa-eye" />
                    </Link>
                  ),
                },
              ],
              []
            );

          return (
            <>
              <Helmet
                title="Art2vendreimmobiler - liste des utilisateurs"
                meta={[
                  {
                    name: "Art2vendreimmobiler - liste des utilisateurs",
                  },
                ]}
              />
              <Row>
                <Col span={24}>
                  <Card
                    title={
                      <h5>
                        <FontAwesome name="users" />
                        Liste des utilisateurs
                      </h5>
                    }
                  >
                    <div className="card">
                      {this.props.user.role.name !== "Admin" ? (
                        <Alert
                          message="Vous devez être administrateur pour consulter cette page"
                          type="error"
                        />
                      ) : (
                        <ScrollTable
                          search
                          list={list}
                          columns={columns}
                          {...this.props}
                          defaultSorted={defaultSorted}
                        />
                      )}
                    </div>
                  </Card>
                </Col>
              </Row>
            </>
          );
        }}
      </Query>
    );
  }
}

export default ListUsers;
