import React from "react";
import {Popover} from "antd";
import {Link} from "react-router-dom";

import FontAwesome from "react-fontawesome";
import Loading from "../Loading";

class UserPopOver extends React.PureComponent {
  render() {
    const {user} = this.props;

    if (!user) {
      return <Loading />;
    }

    const content = (
      <div>
        <p>
          <Link to={`/users/${user.id}`}>
            <span>
              <FontAwesome name="user" />
              {user.firstname} {user.lastname}
            </span>
          </Link>
        </p>
        <p>
          <Link to="/logout">
            <span>
              <FontAwesome name="sign-out-alt" />
              Déconnexion
            </span>
          </Link>
        </p>
      </div>
    );

    return [
      <Popover
        key={user.id}
        placement="bottomRight"
        title="Mon compte"
        content={content}
        arrowPointAtCenter
      >
        <span style={{color: "white"}}>
          <FontAwesome
            style={{fontSize: 18, position: "relative", top: 2}}
            name="user-circle"
          />
          Mon compte
        </span>
      </Popover>,
    ];
  }
}

export default UserPopOver;
