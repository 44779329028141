import React from "react";
// Get the custom `history` that we'll use to feed down to our `<Router>`
import {history} from "src/router/history";
import {Loading} from "src/components/stateless";

class Logout extends React.Component {
  async componentDidMount() {
    localStorage.removeItem("adv_stats");
    window.location.href = "/";
  }

  render() {
    return <Loading />;
  }
}

export default Logout;
