import React from "react";
import {Spin} from "antd";

const Loading = () => (
  <div className="center-screen">
    <Spin size="large" />
  </div>
);

export default Loading;
