import React, {useLayoutEffect, useRef, useEffect} from "react";
import TreeChart from "d3-org-chart";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const OrgChartComponent = (props, ref) => {
  const d3Container = useRef(null);
  let chart = null;

  function addNode(node) {
    chart.addNode(node);
  }

  const {height, width} = useWindowDimensions();

  // We need to manipulate DOM
  useLayoutEffect(() => {
    if (props.data && d3Container.current) {
      if (!chart) {
        chart = new TreeChart();
      }
      chart
        .container(d3Container.current)
        .data(props.data)
        .svgWidth(width)
        .svgHeight(props.height || height)
        .initialZoom(0.4)
        // .onNodeClick(d => {
        //   console.log(d + " node clicked");
        //   console.log("props", Object.keys(props), d);
        //   props.onNodeClick(d);
        // })
        .render();
    }
  }, [props.data, d3Container.current]);

  return (
    <div>
      <div ref={d3Container} style={{height, width}} />
    </div>
  );
};
