import React, {Component} from "react";
import {DeleteOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import {Button, List} from "antd";
import {FormInput} from "components/stateless/MaskedInput";
import {updateAffaire} from "src/actions/affaires";
import {showNotification} from "components/stateless";

const uid = require("uuid").v4;

class DtIdForm extends Component {
  constructor(props) {
    super(props);
    this.formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 5,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 19,
        },
      },
    };
    this.state = {
      dtIds: this.props.dtIds,
    };
  }

  componentWillReceiveProps = nextProps => {
    if (!nextProps) {
      return;
    }
    const {dtIds} = this.state;
    if (dtIds.length !== nextProps.dtIds.length) {
      this.setState({
        dtIds: nextProps.dtIds,
      });
    }
  };

  onDtidChange = (e, key) => {
    if (e.currentTarget.value.replace(/_/g, "").length === 16) {
      const index = this.state.dtIds.findIndex(d => d.key === key);
      const dtIds = [...this.state.dtIds];
      dtIds[index].value = e.currentTarget.value;
      this.props.onDtIdChange(dtIds);
    }
  };

  onSubmit = async item => {
    console.log(this.props);
    if (item.value.replace(/_/g, "").length === 16) {
      // return directly if affaire id not know yet
      //const dtIds = [...this.state.dtIds, item];
      // else save to db
      this.saveDtIds(this.state.dtIds);
    }
  };

  saveDtIds = async dtIds => {
    if (!this.props.affaireId) {
      return this.props.onDtIdChange(dtIds);
    }
    const data = {
      id: this.props.affaireId,
      dtIds: dtIds.map(d => d.value),
    };
    try {
      const current = await updateAffaire(data, this.props.client);
      const dtIds = current.dtIds.map(d => {
        return {
          saved: true,
          key: uid(),
          value: d,
        };
      });
      this.setState({
        dtIds,
      });
      this.props.onDtIdChange(dtIds);
      showNotification("success", "Succès", "Liste des DT mise à jour avec succès");
    } catch (err) {
      console.log(err);
      showNotification(
        "error",
        "Erreur",
        `Impossible d'enregistrer vos numeros de DT: ${err.message.replace(
          "GraphQL error:",
          ""
        )}`
      );
    }
  };

  onCreateDtId = () => {
    const dtIds = [
      ...this.state.dtIds,
      {
        key: uid(),
        value: "",
        saved: false,
      },
    ];
    this.props.onDtIdChange(dtIds);
  };

  onDeleteDtId = key => {
    let dtIds = [];
    dtIds = this.state.dtIds.filter(i => i.key !== key);
    this.props.form.resetFields();
    this.saveDtIds(dtIds);
  };

  renderDtIds = (item, i) => (
    <List.Item key={item.key}>
      <div
        style={{
          display: "inline-flex",
        }}
      >
        <FormInput
          formItemLayout={{
            ...this.formItemLayout,
          }}
          form={this.props.form || null}
          initialValue={item.value || ""}
          onChange={e => this.onDtidChange(e, item.key)}
          mask="1111111111111A11"
          value={item.value}
          prefix={
            <i
              className="fa fa-id-badge"
              style={{
                color: "rgba(0,0,0,.25)",
              }}
            />
          }
          label={`DT ${i + 1}`}
          name={`dt-${i + 1}`}
          placeholder=""
          rules={[
            {
              pattern: new RegExp(
                "^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][A-Z][A-Z0-9][A-Z0-9]$"
              ),
              message: "Mauvais format de numéro... !",
              required: true,
              whitespace: true,
            },
          ]}
        />
        <Button
          onClick={() => this.onDeleteDtId(item.key)}
          type="default"
          icon={<DeleteOutlined />}
          style={{
            width: 32,
            top: 4,
          }}
        />
        {!item.saved &&
          this.props.affaireId &&
          item.value.replace(/_/g, "").length === 16 && (
            <Button
              onClick={() => this.onSubmit(item)}
              type="danger"
              icon={<SaveOutlined />}
              style={{
                width: 32,
                top: 4,
              }}
            />
          )}
      </div>
    </List.Item>
  );

  render() {
    const {dtIds} = this.state;

    return (
      <List
        header={<div align="center">Liste des numéros de DT</div>}
        bordered
        dataSource={dtIds}
        renderItem={this.renderDtIds}
      >
        <div
          style={{
            margin: "10px 20px 10px 25px",
          }}
        >
          <Button
            onClick={this.onCreateDtId}
            type="default"
            icon={<PlusOutlined />}
            style={{
              marginRight: 10,
            }}
          />
          Ajouter un numéro de DT
        </div>
      </List>
    );
  }
}

export default DtIdForm;
