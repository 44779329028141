import React from "react";
import moment from "moment";
import {Row, Col, Card} from "antd";
import {Link} from "react-router-dom";
import {pulse} from "react-animations";
import {StyleSheet, css} from "aphrodite";
import FontAwesome from "react-fontawesome";
import {Loading, showNotification} from "src/components/stateless"; // custom components
import {orderBy} from "lodash";
import styles from "./styles.module.scss";
import {Query} from "react-apollo";
import gql from "graphql-tag";

import {getGeneralStats} from "src/actions/stats";

const animStyles = StyleSheet.create({
  pulse: {
    ":hover": {
      animationName: pulse,
      animationDuration: "0.5s",
    },
  },
});

class Stats extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      stats: {},
      loading: true,
    };
  }

  componentDidMount = async () => {
    const data = await getGeneralStats(this.props.client);
    this.props.client
      .subscribe({
        query: gql`
          subscription {
            statsUpdated {
              transactions
              userGains
              a2vGainsCount
              a2vGainsTransactionsCount
              userTotalGains
              userPayed
            }
          }
        `,
        variables: {},
      })
      .subscribe(async (data, client) => {
        try {
          const data = await getGeneralStats(this.props.client);
          this.setState({stats: data});
        } catch (err) {
          console.log(err);
        }
      });
    this.setState({stats: data, loading: false});
  };

  render() {
    const {loading, stats} = this.state;

    if (loading) return <Loading />;

    return (
      <div className={styles.statsContainer} id="statsContainer">
        <Row type="flex" justify="center" align="middle" alignItems="middle">
          <Col
            span={5}
            className={[styles.stats, css(animStyles.pulse)]}
            style={{background: "#970AE7"}}
          >
            <FontAwesome name="file-signature" />
            {stats.transactions}

            <h3>Mes Transactions</h3>
          </Col>
          {this.props.user.childrens.length !== 0 && (
            <>
              <Col
                span={5}
                className={[styles.stats, css(animStyles.pulse)]}
                style={{background: "#f2ad15"}}
              >
                <Link to="/earnings" style={{color: "white"}}>
                  <FontAwesome name="file-invoice" />
                  {this.props.user.needTva
                    ? parseFloat(stats.userGains / (1 + 20 / 100)).toFixed(2)
                    : stats.userGains}{" "}
                  €<h3>Récurrences à facturer {this.props.user.needTva ? "(HT)" : ""}</h3>
                </Link>
              </Col>
              <Col
                span={5}
                className={[styles.stats, css(animStyles.pulse)]}
                style={{background: "#1890ff"}}
              >
                <Link to="/earnings" style={{color: "white"}}>
                  <FontAwesome name="receipt" />
                  {this.props.user.needTva
                    ? parseFloat(stats.userPayed / (1 + 20 / 100)).toFixed(2)
                    : stats.userGains}{" "}
                  €<h3>Récurrences Payées {this.props.user.needTva ? "(HT)" : ""}</h3>
                </Link>
              </Col>
            </>
          )}
          {/* {this.props.user.role.name === "Admin" && (
            <Col
              span={5}
              className={[styles.stats, css(animStyles.pulse)]}
              style={{background: "#1890ff"}}
            >
              <Link to="/earnings" style={{color: "white"}}>
                <span className="fa fa-building" aria-hidden="true" />
                {parseFloat(stats.a2vGainsCount / (1 + 20 / 100)).toFixed(2)} €
                <h3>Bénéfices A2V (HT)</h3>
              </Link>
            </Col>
          )} */}
          <Col
            span={5}
            className={[styles.stats, css(animStyles.pulse)]}
            style={{background: "#58c41a"}}
          >
            <span className="fa fa-coins" aria-hidden="true" />
            {this.props.user.needTva
              ? parseFloat(stats.userTotalGains / (1 + 20 / 100)).toFixed(2)
              : stats.userTotalGains}{" "}
            €<h3>Bénéfice total {this.props.user.needTva ? "(HT)" : ""}</h3>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Stats;
